// CONFIDENTIAL. Distribution Only to Partners Under Nondisclosure. Microsoft makes no warranties, express or implied.
// Copyright © Microsoft. All rights reserved.
import {
  HoloVideoObject,
  HoloVideoFrontEnd,
  FileInfo,
  FrameInfo,
  States,
  OpenOptions,
  ErrorStates,
  CreateOptions,
  Context,
  WebGlClientBuffers,
  WebGlBackend,
} from '@MixedRealityCaptureStudios/holo-video-object';

import * as Three from 'three';

export type HvoMesh = Three.Mesh<Three.BufferGeometry, Three.MeshBasicMaterial | Three.MeshPhysicalMaterial>;

type GlContext = WebGLRenderingContext | WebGL2RenderingContext;

export class HoloVideoObjectThreeJS extends HoloVideoFrontEnd<GlContext> {
  private renderer: Three.WebGLRenderer;
  private unlitMaterial: Three.MeshBasicMaterial;
  private litMaterial: Three.MeshPhysicalMaterial;

  gl: GlContext;
  clientBuffers: WebGlClientBuffers;

  private mesh: HvoMesh;
  private bufferGeometry: Three.BufferGeometry;

  protected _createHoloVideoObject(
    context: Context,
    createOptions?: CreateOptions,
    updateCurrentFrameCallback?: (frame: number) => void,
    errorCallback?: (type: ErrorStates, info?: Error | MediaError) => void,
  ): HoloVideoObject<GlContext> {
    const hvo = new HoloVideoObject(context, createOptions, errorCallback);
    hvo.onUpdate = this.onUpdate.bind(this);
    return hvo;
  }

  /**
   * Creates a new HoloVideoObjectThreeJS instance
   * @param {Three.WebGLRenderer} renderer - WebGLRenderer that will be used to render the capture.
   * @param {HoloVideoObjectThreeJS~openCallback} - Callback invoked when initial loading is complete.
   * @param {Object} createOptions - Optional collection of options that permanently affect behavior of this HoloVideoObjectThreeJS instance.
   * @param {boolean} createOptions.disableAsyncDecode - Disables asynchronous video decoding path which may result in improved audio sync but incurs a performance penalty. This is the only decoding path available in WebGL1 environments.
   * @param {number} createOptions.numAsyncFrames - Controls how many asynchronous frames are buffered in WebGL2 async. decode path resulting in 'numAsyncFrames' - 1 frames of latency. The default value is 3.
   * @param {boolean} createOptions.disableTransformFeedback - Disables the plugin's default WebGL2 geometry update path while still allowing WebGL2 texture updates. Default is false.
   * @param {function} - Callback invoked when currentFrame is updated
   * @param {HoloVideoObject~errorCallback} - Callback invoked when unexpected error condition is encountered, see {@link HoloVideoObject#ErrorStates} for list of possible conditions.
   */
  constructor(
    renderer: Three.WebGLRenderer,
    callback: (mesh: HvoMesh) => void,
    createOptions?: CreateOptions,
    updateCurrentFrameCallback?: (frame: number) => void,
    errorCallback?: (type: ErrorStates, info?: Error | MediaError) => void,
  ) {
    super(
      renderer.getContext(),
      (fileInfo) => {
        this.onLoaded(fileInfo)
        callback(this.mesh);
      },
      {
        outputLinearTextures: renderer.outputEncoding === Three.sRGBEncoding,
        ...createOptions,
      },
      updateCurrentFrameCallback,
      errorCallback,
    );

    this.renderer = renderer;

    const gl = renderer.getContext();
    this.gl = gl;

    this.clientBuffers = {
      tex: gl.createTexture(),
      indexBuf: gl.createBuffer(),
      norBuf: gl.createBuffer(),
      posBuf: gl.createBuffer(),
      uvBuf: gl.createBuffer(),
    };

    this.unlitMaterial = new Three.MeshBasicMaterial({ map: null, transparent: false, side: Three.DoubleSide });
    this.litMaterial = new Three.MeshPhysicalMaterial({
      map: null, transparent: false, side: Three.FrontSide, roughness: 1, metalness: 0,
    });
  }

  public release(): void {
    this.unlitMaterial.dispose();
    this.litMaterial.dispose();
  }

  public onLoaded(fileInfo: FileInfo): void {
    this._fileInfo = fileInfo;

    const useNormals = fileInfo.haveNormals;
    const indexType = fileInfo.indicesComponentType;

    if ((this.hvo.getGraphicsBackend() as WebGlBackend).contextRestoring) {
      if (this.mesh) {
        this.mesh.material.map = this._setVertexAttributes(this.mesh.geometry);
        this.mesh.material.needsUpdate = true;
      }

      return;
    }

    if (this.mesh) {
      // if index type changes just re-create the whole mesh
      const indexAt = this.mesh.geometry.getIndex();
      const normalAt = this.mesh.geometry.getAttribute('normal');
      const haveNormals = normalAt != undefined;
      if ((indexAt as any).type != indexType || haveNormals != useNormals) {
        this.mesh = null;
      } else {
        const material = useNormals ? this.litMaterial : this.unlitMaterial;
        material.map = (this.mesh.material as Three.MeshBasicMaterial).map;
        this.mesh.material = material;
      }
    }

    if (!this.mesh) {
      const bufferGeometry = new Three.BufferGeometry();
      bufferGeometry.boundingSphere = new Three.Sphere();
      bufferGeometry.boundingSphere.set(new Three.Vector3(), Infinity);
      bufferGeometry.boundingBox = new Three.Box3();
      bufferGeometry.boundingBox.set(new Three.Vector3(-Infinity, -Infinity, -Infinity), new Three.Vector3(+Infinity, +Infinity, +Infinity));

      const material = useNormals ? this.litMaterial : this.unlitMaterial;
      material.map = this._setVertexAttributes(bufferGeometry);

      const mesh = new Three.Mesh(bufferGeometry, material);
      mesh.scale.x = 0.001;
      mesh.scale.y = 0.001;
      mesh.scale.z = 0.001;

      this.mesh = mesh;
      this.bufferGeometry = bufferGeometry;
    }
  }

  public getMesh() {
    return this.mesh;
  }

  private _setVertexAttributes(bufferGeometry: Three.BufferGeometry): Three.Texture {
    const { gl } = this;

    // 10/7/2020
    // TODO: Once most clients are using the latest three.js versions(at least r120), we should remove the three.js revision check.
    const { posBuf } = this.clientBuffers;
    // TODO(Jordan): Since we're using Three from npm we know the version ahead of time.
    // Consider if we need this version check or if there's still some other way to
    // support multiple versions of Three, like when using our umd build for example.
    const revision = Number(Three.REVISION);
    const posAttr = revision >= 120
      ? new (Three as any).GLBufferAttribute(posBuf, gl.FLOAT, 3, 0)
      : new (Three as any).GLBufferAttribute(gl, posBuf, gl.FLOAT, 3, 0);
    bufferGeometry.setAttribute('position', posAttr);

    if (this.clientBuffers.norBuf) {
      const norAttr = revision >= 120
        ? new (Three as any).GLBufferAttribute(this.clientBuffers.norBuf, gl.FLOAT, 3, 0)
        : new (Three as any).GLBufferAttribute(gl, this.clientBuffers.norBuf, gl.FLOAT, 3, 0);
      bufferGeometry.setAttribute('normal', norAttr);
    }

    const { uvBuf } = this.clientBuffers;
    const uvAttr = revision >= 120
      ? new (Three as any).GLBufferAttribute(uvBuf, gl.UNSIGNED_SHORT, 2, 0)
      : new (Three as any).GLBufferAttribute(gl, uvBuf, gl.UNSIGNED_SHORT, 2, 0);
    uvAttr.normalized = true;
    bufferGeometry.setAttribute('uv', uvAttr);

    const { indexBuf } = this.clientBuffers;
    const indAttr = revision >= 120
      ? new (Three as any).GLBufferAttribute(indexBuf, this._fileInfo.indicesComponentType, 0, 0)
      : new (Three as any).GLBufferAttribute(gl, indexBuf, this._fileInfo.indicesComponentType, 0, 0);
    bufferGeometry.setIndex(indAttr);

    const texture = new Three.Texture();
    const texProps = this.renderer.properties.get(texture);
    texProps.__webglTexture = this.clientBuffers.tex;

    var saveTex = gl.getParameter(gl.TEXTURE_BINDING_2D);
    gl.bindTexture(gl.TEXTURE_2D, texProps.__webglTexture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, this._fileInfo.videoWidth, this._fileInfo.videoHeight, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.bindTexture(gl.TEXTURE_2D, saveTex);

    this.hvo.setBuffers(this.clientBuffers);

    return texture;
  }

  private onUpdate(updated: boolean, currentFrameInfo: FrameInfo): void {
    if (!updated) {
      return;
    }

    const min = currentFrameInfo.bboxMin;
    const max = currentFrameInfo.bboxMax;

    const bufferGeometry = this.bufferGeometry;

    bufferGeometry.boundingBox.min.x = min[0];
    bufferGeometry.boundingBox.min.y = min[1];
    bufferGeometry.boundingBox.min.z = min[2];
    bufferGeometry.boundingBox.max.x = max[0];
    bufferGeometry.boundingBox.max.y = max[1];
    bufferGeometry.boundingBox.max.z = max[2];

    bufferGeometry.boundingBox.getCenter(bufferGeometry.boundingSphere.center);
    const maxSide = Math.max(max[0] - min[0], max[1] - min[1], max[2] - min[2]);
    bufferGeometry.boundingSphere.radius = maxSide * 0.5;

    bufferGeometry.index.count = currentFrameInfo.primCount;
  }

  public onClose(): void {
    if (this.bufferGeometry) {
      this.bufferGeometry.index.count = 0;
    }
  }
}

export { States, OpenOptions };
