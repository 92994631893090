// CONFIDENTIAL. Distribution Only to Partners Under Nondisclosure. Microsoft makes no warranties, express or implied.
// Copyright © Microsoft. All rights reserved.
import { version } from './package-version';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
  _extName,
  Logger,
  Extensions,
  Accessor,
  Mesh,
  CreateOptions,
  BrowserInfo,
  FileInfo,
  OpenOptions,
  StreamMode,
  MeshState,
  FrameInfo,
  Version,
} from './holoVideoInterface';
import {
  MeshStateWebGL,
  WebGlBackend,
  WebGlClientBuffers,
  TypedArrayClientBuffers as GlTypedArrayClientBuffers,
} from './WebGlBackend';
import {
  WebGpuBackend,
  WebGpuClientBuffers,
  TypedArrayClientBuffers as GpuTypedArrayClientBuffers,
} from './WebGpuBackend';

declare const dashjs: any;

const MAJOR_PLACEHOLDER = version.major;
const MINOR_PLACEHOLDER = version.minor;
const PATCH_PLACEHOLDER = version.patch;
const GL_UNSIGNED_SHORT = 5123;

const clamp = (num: number, min: number, max: number) => (num < min ? min : num > max ? max : num);

/**
 * HoloVideoObject VideoStates
 * @readonly
 * @enum {number}
 */
enum VideoStates {
  Undefined = 0,
  CanPlay = 1,
  CanPlayThrough = 2,
  Waiting = 3,
  Suspended = 4,
  Stalled = 5,
  Playing = 6,
}

/**
 * HoloVideoObject ErrorStates
 * @readonly
 * @enum {number}
 */
export enum ErrorStates {
  NetworkError = -1, // Network transfer failed.
  VideoError = -2, // Error reported by media element, associated error Event will be forwarded to error callback.
  PlaybackPrevented = -3, // Video playback was prevented by browser (possibly due to power saving mode, lack of user interaction, etc). Associated error Event will be forwarded to error callback.
}

/**
 * HoloVideoObject States
 * @readonly
 * @enum {number}
 */
export enum States {
  Closed = -1, // A previously loaded capture has been unloaded. A new capture may be opened by calling {@link HoloVideoObjectThreeJS#open}.
  Empty = 0, // Initial state of all HoloVideoObjectThreeJS instances.
  Opening = 1, // A capture is in the process of opening and buffering data for playback..
  Opened = 2, // The capture loaded and ready for {@link HoloVideoObjectThreeJS#play} to be called.
  Playing = 3, // Capture playback is in progress.
  Paused = 4, // Playback is paused and may be resumed by calling {@link HoloVideoObjectThreeJS#play}.
}

type HvoJson = {
  buffers: { uri: string; loaded: boolean; arrayBufferIndex: number; }[];
  extensions: Extensions;
  images: {
    video: VideoElement;
    extensions: Extensions;
    uri: string;
    bufferView: number;
  }[];
  bufferViews: {
    buffer: number;
    byteLength: number;
    byteOffset: number;
    byteStride: number;
    extensions: object;
  }[];
  accessors: Accessor[];
  meshes: Mesh[];
};

type VideoElement = HTMLVideoElement &
Partial<{
  videoElementIndex: number;
  preloaded: boolean;
  playing: boolean;
  mp4Name: string;
  canplay: () => void;
  waiting: () => void;
  canplaythrough: () => void;
  suspend: () => void;
  stalled: () => void;
}>;

type GraphicsBackend = WebGlBackend | WebGpuBackend;
type GlRenderingContext = WebGLRenderingContext | WebGL2RenderingContext;
export type Context = GlRenderingContext | GPUCanvasContext;

// Helper type for selecting the right client buffers depending on context.
type ClientBuffers<T extends Context> = (
  T extends GlRenderingContext ? WebGlClientBuffers :
  T extends GPUCanvasContext ? WebGpuClientBuffers :
  never
);

type TypedArrayClientBuffers<T extends Context> = (
  T extends GlRenderingContext ? GlTypedArrayClientBuffers :
  T extends GPUCanvasContext ? GpuTypedArrayClientBuffers :
  never
);

export class HoloVideoObject<TContext extends Context> implements Logger {
  id: number;
  state: States;
  private graphicsBackend: GraphicsBackend;
  private json: HvoJson;
  private suspended: boolean;
  private seekingAutoPlay: boolean | undefined; // Can be deleted.
  private seekingStartBufferIndex: number | undefined; // Can be deleted.
  private fallbackFrameBuffer: ArrayBuffer | null;
  private nextBufferLoadIndex: number;
  private pendingBufferDownload: boolean;
  private seekTargetTime: number | undefined;
  private searchStartFrame?: number;
  private seeking: boolean;
  private frameIndex: number;
  private lastUpdate: number;
  lastVideoTime: number;
  private currentBufferIndex: number;
  private pauseAfterSeek?: boolean;
  private unmuteAfterSeek: boolean;
  private eos: boolean;

  private audioVolume = 1.0;
  logLevel = 1;

  openOptions: OpenOptions;

  private urlRoot: string;

  private dashPlayer: any;
  private httpRequest: XMLHttpRequest | null;
  private minBuffers: number;
  private buffersLoaded: number;
  private minVideos: number;
  private videosLoaded: number;
  private buffers: ((ArrayBuffer & { bufferIndex: number }) | null)[];
  private freeArrayBuffers: number[];
  private meshState: MeshState;
  private lastKeyframe: number;
  private contextLost: boolean;
  private wasPlaying: boolean; // only used when context lost/restored or when document visibility changes

  onUpdate?: (updated: boolean, frameInfo: FrameInfo) => void;
  onUpdateCurrentFrame?: (frame: number) => void;
  onBeforeLoad?: (openOptions: OpenOptions) => void;
  errorCallback?: (type: ErrorStates, info?: Error | MediaError) => void;
  onEndOfStream?: (self: HoloVideoObject<TContext>) => void;
  onLoaded?: (fileInfo: FileInfo) => void;

  private browserInfo: BrowserInfo;

  fileInfo: FileInfo;
  currentFrameInfo: FrameInfo;
  videoElement: VideoElement | null = null;
  meshFrames: Mesh[];
  private nextVideoLoadIndex: number;
  private videoState: VideoStates;
  private lastVideoSampleIndex: number;
  private pendingVideoEndEvent: boolean;
  private needMeshData: boolean;
  private pendingVideoEndEventWaitCount: number;
  private fallbackTextureImage: (HTMLImageElement & { loaded?: boolean }) | null;
  private oldVideoSampleIndex: number;
  private filledFallbackFrame: boolean;
  private requestKeyframe: boolean;

  static _instanceCounter = 0;

  static Version = {
    Major: MAJOR_PLACEHOLDER,
    Minor: MINOR_PLACEHOLDER,
    Patch: PATCH_PLACEHOLDER,
    String: `${MAJOR_PLACEHOLDER}.${MINOR_PLACEHOLDER}.${PATCH_PLACEHOLDER}`,
  };

  private _loadJSON(src: string, callback: (text: string) => void): string {
    // native json loading technique from @KryptoniteDove:
    // http://codepen.io/KryptoniteDove/post/load-json-file-locally-using-pure-javascript

    var xobj = new XMLHttpRequest();
    xobj.overrideMimeType('application/json');
    xobj.onreadystatechange = () => {
      if (
        xobj.readyState == 4 && // Request finished, response ready
        xobj.status == 200
      ) {
        // Status OK
        callback(xobj.responseText);
      } else if (xobj.status >= 400) {
        this._logError('_loadJSON failed for: ' + src + ', XMLHttpRequest status = ' + xobj.status);
        this._onError(ErrorStates.NetworkError);
      }
    };
    xobj.onerror = () => {
      this._logError('_loadJSON XMLHttpRequest error for: ' + src + ', status = ' + xobj.status);
      this._onError(ErrorStates.NetworkError);
    };
    xobj.open('GET', src, true);
    xobj.send(null);
    return xobj.responseText;
  }

  private _loadArrayBuffer(url: string, callback: (arrayBuffer: ArrayBuffer) => void) {
    var xobj = new XMLHttpRequest();
    // TODO(Jordan): TS complains that name isn't a property of XMLHttpRequest.
    (xobj as any).name = url.substring(url.lastIndexOf('/') + 1, url.length);
    xobj.responseType = 'arraybuffer';
    xobj.onprogress = (e) => {
      if (e.lengthComputable) {
        //var percentComplete = Math.floor((e.loaded / e.total) * 100);
        //this._logInfo(xobj.name + " progress: " + percentComplete);
      }
    };
    xobj.onreadystatechange = () => {
      if (xobj.readyState == 4) {
        // Request finished, response ready
        if (xobj.status == 200) {
          // Status OK
          var arrayBuffer = xobj.response;
          if (arrayBuffer && callback) {
            callback(arrayBuffer);
          }
        } else if (xobj.status >= 400) {
          this._logError('_loadArrayBuffer failed for: ' + url + ', XMLHttpRequest status = ' + xobj.status);
          this._onError(ErrorStates.NetworkError);
        } else {
          this._logWarning('_loadArrayBuffer unexpected status = ' + xobj.status);
        }
        if (this.httpRequest == xobj) {
          this.httpRequest = null;
        }
      }
    };
    xobj.ontimeout = () => {
      this._logError('_loadArrayBuffer timeout');
      this._onError(ErrorStates.NetworkError);
    };
    xobj.open('GET', url, true);
    xobj.send(null);
    this.httpRequest = xobj;
  }

  private _startPlaybackIfReady(): void {
    if (this.state == States.Opening) {
      if (this.buffersLoaded >= this.minBuffers && this.videosLoaded >= this.minVideos) {
        this._logInfo('state -> Opened');
        this.state = States.Opened;

        if (this.openOptions.onReady) {
          this.openOptions.onReady();
        }

        if (this.openOptions.autoplay) {
          this.play();
        } else if (this.seekingAutoPlay) {
          delete this.seekingAutoPlay;
          this.play();
        }
      }
    } else if (this.seekingAutoPlay) {
      if (this.buffersLoaded >= this.minBuffers && this.videosLoaded >= this.minVideos) {
        delete this.seekingAutoPlay;
        this.play();
      }
    }

    // not else if
    if (this.suspended) {
      let currentVideo = this._currentVideo();
      if ((currentVideo.paused || !currentVideo.playing) && currentVideo.preloaded) {
        this._logInfo('video ' + currentVideo.mp4Name + ' was suspended, resuming');
        this.suspended = false;
        currentVideo.play();
      }
    } else if (this.state == States.Playing) {
      let currentVideo = this._currentVideo();
      if (!currentVideo.playing) {
        currentVideo.play();
      }
    }
  }

  private _isBufferAlreadyLoaded(bufferIndex: number): boolean {
    for (let i = 0; i < this.buffers.length; ++i) {
      if (this.buffers[i]?.bufferIndex == bufferIndex) {
        return true;
      }
    }
    return false;
  }

  private _loadNextBuffer(): void {
    if (this.freeArrayBuffers.length == 0) {
      if (this.openOptions.keepAllMeshesInMemory) {
        this._logInfo('All meshes loaded.');
        return;
      }

      this._logInfo('_loadNextBuffer: Waiting for next free buffer...');
      return;
    }

    let bufferIndex: number;

    // seeking after prior Open call (already downloaded fallbackFrameBuffer), start downloading from 'seekingStartBufferIndex' immediately
    if (this.seekingStartBufferIndex && this.fallbackFrameBuffer) {
      bufferIndex = this.nextBufferLoadIndex = this.seekingStartBufferIndex;
      delete this.seekingStartBufferIndex;
    } else {
      bufferIndex = this.nextBufferLoadIndex;
    }

    // Opening with a non-zero start time, download fallback buffer (just to have it out of the way) then start downloading from seek target buffer
    if (this.seekingStartBufferIndex) {
      this.nextBufferLoadIndex = this.seekingStartBufferIndex;
      delete this.seekingStartBufferIndex;
    } else {
      do {
        this.nextBufferLoadIndex = (this.nextBufferLoadIndex + 1) % this.json.buffers.length;
      } while (this._isBufferAlreadyLoaded(this.nextBufferLoadIndex));
    }

    if (this.fallbackFrameBuffer && this.nextBufferLoadIndex == 0) {
      this.nextBufferLoadIndex = 1;
    }

    var buffer = this.json.buffers[bufferIndex];
    var bufferName = buffer.uri;
    var bufferURL = this.urlRoot + bufferName;
    buffer.loaded = false;

    var arrayBufferIndex = -1;

    if (bufferIndex == 0) {
      this._logInfo('loading preview frame buffer');
    } else {
      arrayBufferIndex = this.freeArrayBuffers.shift()!;
      this._logInfo('loading buffer: ' + buffer.uri + ' into slot ' + arrayBufferIndex);
    }

    this.pendingBufferDownload = true;
    this._loadArrayBuffer(bufferURL, (arrayBuffer: ArrayBuffer & { bufferIndex: number }) => {
      if (!this.fallbackFrameBuffer && !this.filledFallbackFrame) {
        this._logInfo('fallback frame buffer downloaded ' + buffer.uri);
        this.fallbackFrameBuffer = arrayBuffer;

        this._loadNextBuffer();
        this.pendingBufferDownload = false;
        return;
      }

      this._logInfo('buffer loaded: ' + buffer.uri + ' into slot ' + arrayBufferIndex);

      ++this.buffersLoaded;

      this.buffers[arrayBufferIndex] = arrayBuffer;
      arrayBuffer.bufferIndex = bufferIndex; // which buffer in timeline is loaded into this arrayBuffer

      // so buffer knows which arrayBuffer contains its data... don't reference arrayBuffer directly because we only want to keep 3 arrayBuffers in memory at a time.
      buffer.arrayBufferIndex = arrayBufferIndex;
      buffer.loaded = true;
      this.pendingBufferDownload = false;
      this.needMeshData = false; // is this really true?

      this._startPlaybackIfReady();
      this._loadNextBuffer();
    });
  }

  private _setSeekTarget(seekTime: number): void {
    this.seekTargetTime = seekTime;
    this.searchStartFrame = this._computeSeekSearchFrame(this.seekTargetTime);
    this.seeking = true;

    let ext = this.json.extensions[_extName];
    let currentFrameTime = this.frameIndex / ext.framerate;

    if (this.searchStartFrame != this.lastKeyframe || this.seekTargetTime < currentFrameTime) {
      this.requestKeyframe = true;
    }
  }

  private _frameIsKeyframe(frameIndex: number): boolean {
    let frame = this.meshFrames[frameIndex];
    return frame.indices != undefined;
  }

  private _computeSeekSearchFrame(targetTimeSec: number): number {
    let ext = this.json.extensions[_extName];
    let keyframes = ext.keyframes;
    for (let i = keyframes.length - 1; i >= 0; --i) {
      let timestamp = keyframes[i] / ext.framerate;
      if (timestamp <= targetTimeSec) {
        return keyframes[i];
      }
    }

    return 0;
  }

  // find keyframe prior to target time
  private _computeSeekSearchTime(targetTimeSec: number): number {
    let ext = this.json.extensions[_extName];
    let keyframe = this._computeSeekSearchFrame(targetTimeSec);
    let timestamp = keyframe / ext.framerate;
    return timestamp;
  }

  private _currentVideo(): VideoElement {
    const timeline = this.json.extensions[_extName].timeline;
    const image = this.json.images[timeline[0].image];
    const currentVideo = image.video;
    return currentVideo;
  }

  seekToTime(seekTimeMs: number, displayImmediately: boolean): void {
    if (this.seeking) {
      this._logWarning('seekToTime: ignoring request due to prior seek in-progress');
      return;
    }

    if (this.httpRequest) {
      this.httpRequest.abort();
      this.httpRequest = null;
    }

    let currentVideo = this._currentVideo();

    let wasPlaying = false;

    if (this.state == States.Playing) {
      wasPlaying = true;
      this.pause();
      currentVideo.playing = false;
      this.seekingAutoPlay = true;
    }

    this._setSeekTarget(seekTimeMs);
    let frame = this.meshFrames[this.searchStartFrame!];
    const bufferViews = this.json.bufferViews;

    // the buffer containing our target frame
    let bufferIndex = bufferViews[frame.indices.bufferView].buffer;

    let buffersNeeded = [];
    let index = bufferIndex;
    for (let i = 0; i < Math.min(this.openOptions.maxBuffers!, this.json.buffers.length); ++i) {
      // skip over preview/fallback buffer
      if (index == 0) {
        index = 1;
      }
      buffersNeeded.push(index);
      index = (index + 1) % this.json.buffers.length;
    }

    let newStartBuffer = true;
    this.currentBufferIndex = -1;

    this.buffersLoaded = 0;
    let freeBuffers = [];
    // look at our already-loaded array buffers, and see if any of them are already holding buffers we need for the new playback position
    for (let i = 0; i < this.buffers.length; ++i) {
      // Skip this buffer if it isn't filled out.
      if (!this.buffers[i]) {
        freeBuffers.push(i);
        continue;
      }

      let buffer = this.buffers[i]!.bufferIndex;

      // we already have our new start position loaded
      if (buffer == bufferIndex) {
        newStartBuffer = false;
        this.currentBufferIndex = i;
      }

      // don't need this buffer so free up the slot
      if (buffersNeeded.indexOf(buffer) == -1) {
        freeBuffers.push(i);
      } else {
        ++this.buffersLoaded;
      }
    }

    if (!this.openOptions.keepAllMeshesInMemory) {
      this.freeArrayBuffers = freeBuffers;
    }

    if (newStartBuffer) {
      this.seekingStartBufferIndex = bufferIndex;
    }

    if (!wasPlaying && displayImmediately) {
      this.seekingAutoPlay = true; // so 'startPlaybackIfReady' will initiate playback
      this.pauseAfterSeek = true;
    }

    // used to tell if we actually advanced any frames since we started seeking, or if video element is still displaying last frame from before seek.
    this.oldVideoSampleIndex = this.lastVideoSampleIndex;

    //this.frameIndex = -1;
    this.lastVideoSampleIndex = -1;

    if (this.requestKeyframe) {
      this.lastKeyframe = -1;
      this.meshState.lastKeyframeIndices = null;
      this.meshState.lastKeyframeUVs = null;
      this.meshState.curMesh = null;
      this.meshState.prevMesh = null;
      this.meshState.prevPrevMesh = null;
    }

    this._setVideoStartTime(currentVideo);

    this.graphicsBackend.onSeekToTimeCompleted();

    // we already have all the buffers we need to start playback
    if (freeBuffers.length == 0) {
      this._startPlaybackIfReady();
    } else {
      this._loadNextBuffer();
    }

    this._logDebug('seekToTime: targetTime = ' + seekTimeMs + ', search start = ' + this.searchStartFrame + ' (in buffer ' + bufferIndex + ')');
  }

  private _setVideoStartTime(video: VideoElement) {
    if (this.seekTargetTime) {
      // the time of the last keyframe before target time
      let searchStart = this._computeSeekSearchTime(this.seekTargetTime);

      const oldTime = video.currentTime;

      // if we don't need a keyframe and we're already closer than 'searchStart' time then don't seek video
      if (this.requestKeyframe) {
        //this.dashPlayer.seek(searchStart);
        video.currentTime = searchStart;
        this._logDebug('setVideoStartTime: requestKeyframe, video.currentTime was ' + oldTime + ', video.currentTime -> searchStart = ' + searchStart);
      } else if (video.currentTime > this.seekTargetTime) {
        video.currentTime = this.seekTargetTime;
        this._logDebug('setVideoStartTime: back up to seekTargetTime = ' + this.seekTargetTime + ', video.currentTime was ' + oldTime);
      } else {
        this._logDebug("setVideoStartTime: don't touch video.currentTime, was " + oldTime);
      }

      if (!video.muted) {
        video.muted = true;
        this.unmuteAfterSeek = true;
      }
    } else {
      video.currentTime = 0.0;
    }
  }

  private cleanUpEventListeners: (() => void) | null = null;
  private videoElementInitialized = false;

  private _loadNextVideo() {
    if (this.videoElementInitialized) {
      return;
    }

    this.videoElementInitialized = true;

    const video = this.videoElement;
    var videoIndex = this.nextVideoLoadIndex;
    var numVideos = this.json.extensions[_extName].timeline.length;
    this.nextVideoLoadIndex = (this.nextVideoLoadIndex + 1) % numVideos;
    var image = this.json.images[this.json.extensions[_extName].timeline[videoIndex].image];

    image.video = video;
    video.preloaded = false;

    video.autoplay = false;
    video.muted = this.openOptions.autoplay || !this.openOptions.audioEnabled;

    // Safari won't preload unmuted videos
    if (this.browserInfo.isSafari) {
      video.muted = true;
    }

    video.loop = numVideos == 1 && !!this.openOptions.autoloop;
    video.preload = 'auto';
    video.crossOrigin = this.openOptions.crossOrigin ?? 'use-credentials';
    video.playing = false;
    video.preloaded = false;
    video.src = null!;

    var imageExt = image.extensions[_extName];

    if (this.openOptions.streamMode === undefined) {
      this.openOptions.streamMode = StreamMode.Automatic;
    }

    // iOS/iPadOS 14.0~14.5 has HLS playback issue(https://bugs.webkit.org/show_bug.cgi?id=215908)
    let hasIOS14HLSIssue = this.browserInfo.iOSVersion && this.browserInfo.iOSVersion.major == 14 && this.browserInfo.iOSVersion.minor < 6;

    // HLS video stream is not working on M1 Mac in Safari 14, but there's (seemingly) no way to distinguish an M1 from an Intel Mac,
    // so we're forced to use the heavy-handed solution of forcing the StreamMode.Automatic -> StreamMode.MP4 for *all* Macs w/Safari 14.
    if (!this.browserInfo.iOSVersion && this.browserInfo.safariVersion && this.browserInfo.safariVersion.major < 15) {
      hasIOS14HLSIssue = true;
    }

    if (
      this.openOptions.streamMode == StreamMode.HLS ||
      (this.openOptions.streamMode == StreamMode.Automatic && (this.browserInfo.isSafari || this.browserInfo.isMozillaWebXRViewer) && imageExt.hlsUri && !hasIOS14HLSIssue)
    ) {
      this._setVideoStartTime(video);
      video.src = this.urlRoot + imageExt.hlsUri;
      video.mp4Name = imageExt.hlsUri;
    } else if (
      this.openOptions.streamMode == StreamMode.Dash ||
      (this.openOptions.streamMode == StreamMode.Automatic &&
        !this.browserInfo.isSafari &&
        !this.browserInfo.isMozillaWebXRViewer &&
        imageExt.dashUri &&
        typeof dashjs != 'undefined')
    ) {
      if (!this.dashPlayer) {
        this.dashPlayer = dashjs.MediaPlayer().create();
        this.dashPlayer.initialize();
      }

      var url = this.urlRoot + imageExt.dashUri;

      if (this.seekTargetTime) {
        let searchStart = this._computeSeekSearchTime(this.seekTargetTime);
        url += '#t=' + searchStart;
        this.dashPlayer.attachView(video);
        this.dashPlayer.attachSource(url);
        video.currentTime = searchStart;
        if (!video.muted) {
          video.muted = true;
          this.unmuteAfterSeek = true;
        }
      } else {
        this.dashPlayer.attachView(video);
        this.dashPlayer.attachSource(url);
      }
      video.mp4Name = imageExt.dashUri;
    } else {
      this._setVideoStartTime(video);
      var url = this.urlRoot + image.uri;
      video.src = url;
      video.mp4Name = image.uri;
    }

    this._logInfo('loading video ' + video.mp4Name);

    // Should be more robust than legacy callbacks below: "Not always supported"
    const onCanPlay = () => {
      this.videoState = VideoStates.CanPlay;
    };
    const onPlay = () => {
      this.videoState = VideoStates.Playing;
    };
    const onCanPlayThrough = () => {
      this.videoState = VideoStates.CanPlayThrough;
    };
    const onWaiting = () => {
      this.videoState = VideoStates.Waiting;
    };
    const onSuspend = () => {
      this.videoState = VideoStates.Suspended;
    };
    const onStalled = () => {
      this.videoState = VideoStates.Stalled;
    };

    video.addEventListener('canplay', onCanPlay);
    video.addEventListener('play', onPlay);
    video.addEventListener('canplaythrough', onCanPlayThrough);
    video.addEventListener('waiting', onWaiting);
    video.addEventListener('suspend', onSuspend);
    video.addEventListener('stalled', onStalled);

    this.cleanUpEventListeners = () => {
      video.removeEventListener('canplay', onCanPlay);
      video.removeEventListener('play', onPlay);
      video.removeEventListener('canplaythrough', onCanPlayThrough);
      video.removeEventListener('waiting', onWaiting);
      video.removeEventListener('suspend', onSuspend);
      video.removeEventListener('stalled', onStalled);
    };

    // Not always supported
    video.canplay = () => {
      this._logInfo('video -> canplay');
      this.videoState = VideoStates.CanPlay;
    };

    video.canplaythrough = () => {
      this._logInfo('video -> canplaythrough');
      this.videoState = VideoStates.CanPlayThrough;
    };

    video.waiting = () => {
      this._logInfo('video -> waiting');
      this.videoState = VideoStates.Waiting;
    };

    video.suspend = () => {
      this._logInfo('video -> suspend');
      this.videoState = VideoStates.Suspended;
    };

    video.stalled = () => {
      this._logInfo('video -> stalled');
      this.videoState = VideoStates.Stalled;
    };

    video.onerror = (e: Event | string) => {
      if (e instanceof Event) {
        const target = e.target as VideoElement;
        this._logError('video error: ' + target.error?.code + ' - ' + target.mp4Name);
        this._onError(ErrorStates.VideoError, target.error!);
      } else {
        this._logError('video error: ' + e);
        this._onError(ErrorStates.VideoError);
      }
    };

    video.onended = () => {
      this.pendingVideoEndEvent = true;
      this.pendingVideoEndEventWaitCount = 0;
    };

    if (this.browserInfo.isSafari) {
      video.onplaying = () => {
        video.pause();
        video.muted = this.openOptions.autoplay || !this.openOptions.audioEnabled;
        video.preloaded = true;
        this._logInfo('video loaded: ' + video.mp4Name);

        video.onplaying = () => {
          this._logInfo('video playing: ' + video.mp4Name);
          video.playing = true;
        };

        ++this.videosLoaded;
        this._startPlaybackIfReady();
        this._loadNextVideo();
      };
    } else {
      video.onloadeddata = () => {
        // FIXME: this happens too late for HoloVideoObjectThreeJS to see it (and after onLoaded is called)
        //let currentVideo = this._currentVideo();
        //this.fileInfo.duration = currentVideo.duration;

        var playPromise = video.play();

        if (playPromise !== undefined) {
          // Automatic playback started!
          playPromise
            .then((_) => { })
            .catch((error) => {
              // Auto-play was prevented
              video.onplaying?.(undefined!);
            });
        }
      };

      video.onplaying = () => {
        video.pause();
        video.preloaded = true;
        this._logInfo('video loaded: ' + video.mp4Name);

        video.onplaying = () => {
          this._logInfo('video playing: ' + video.mp4Name);
          video.playing = true;
        };

        ++this.videosLoaded;
        this._startPlaybackIfReady();
        this._loadNextVideo();
      };
    }

    // force preloading
    if (this.browserInfo.isSafari) {
      let playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          // Auto-play was prevented
          this._logWarning('play prevented: ' + error);
          this._onError(ErrorStates.PlaybackPrevented, error);
        });
      }
    }
  }

  private _resetFreeBuffers() {
    this.freeArrayBuffers = [];
    for (var i = 0; i < Math.min(this.openOptions.maxBuffers!, this.json.buffers.length - 1); ++i) {
      this.freeArrayBuffers.push(i);
    }
  }

  rewind(): void {
    if (this.json) {
      this._logInfo('rewind');

      let currentVideo = this._currentVideo();
      currentVideo.pause();
      currentVideo.playing = false;
      currentVideo.currentTime = 0.0;
      this.pendingVideoEndEvent = false;

      this.state = States.Opening;

      if (!this.openOptions.keepAllMeshesInMemory) {
        this._resetFreeBuffers();
      }

      this.currentBufferIndex = 0;
      this.nextBufferLoadIndex = this.fallbackFrameBuffer ? 1 : 0;
      this.frameIndex = -1;
      this.lastKeyframe = -1;
      this.meshState.lastKeyframeIndices = null;
      this.meshState.lastKeyframeUVs = null;
      this.lastVideoSampleIndex = -1;
      this.filledFallbackFrame = false;
      this.meshState.curMesh = null;
      this.meshState.prevMesh = null;
      this.meshState.prevPrevMesh = null;
      delete this.seekTargetTime;

      this.graphicsBackend.onRewind();

      this._loadNextBuffer();
      this._loadFallbackFrame();
      this._startPlaybackIfReady();
    }
  }

  forceLoad(): void {
    if (this.json) {
      let currentVideo = this._currentVideo();

      if (!currentVideo) {
        this._logInfo("forceLoad: don't have currentVideo yet");
      } else if (currentVideo.playing) {
        this._logInfo('forceLoad: video already playing');
      } else if (!currentVideo.preloaded) {
        this._logInfo('forceLoad: manually starting video');
        this.suspended = true;
        var playPromise = currentVideo.play();

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              this.state = States.Playing;
            })
            .catch((error) => {
              // Auto-play was prevented
              this._logWarning('play prevented: ' + error);
              this._onError(ErrorStates.PlaybackPrevented, error);
            });
        }
      }
    } else {
      this._logInfo("forceLoad: don't have json yet");
    }
  }

  private _onVideoEnded(video: VideoElement) {
    this._logInfo('video ended = ' + video.mp4Name);
    this.videoElementInitialized = false;
    var timeline = this.json.extensions[_extName].timeline;
    this.state = States.Opened;

    if (timeline.length - 1 === 0 && !this.openOptions.autoloop) {
      this.eos = true;
      if (this.onEndOfStream) {
        this.onEndOfStream(this);
      }
    } else {
      this._loadNextVideo();
      this._startPlaybackIfReady();
    }
  }

  setBuffers(clientBuffers: ClientBuffers<TContext>): void {
    if (this.graphicsBackend instanceof WebGlBackend) {
      this.graphicsBackend.setBuffers(clientBuffers as WebGlClientBuffers);
    }

    if (this.graphicsBackend instanceof WebGpuBackend) {
      this.graphicsBackend.setBuffers(clientBuffers as WebGpuClientBuffers);
    }
  }

  setBuffersFromTypedArrays(clientBuffers: TypedArrayClientBuffers<TContext>) {
    if (this.graphicsBackend instanceof WebGlBackend) {
      this.graphicsBackend.setBuffersFromTypedArrays(clientBuffers as GlTypedArrayClientBuffers);
    }
    
    if (this.graphicsBackend instanceof WebGpuBackend) {
      this.graphicsBackend.setBuffersFromTypedArrays(clientBuffers as GpuTypedArrayClientBuffers);
    }
  }

  private _updateMesh(updateClientBuffers = false, wasSeeking = false): boolean {
    this.frameIndex = (this.frameIndex + 1) % this.meshFrames.length;

    var frame = this.meshFrames[this.frameIndex];

    if (!frame.ensureBuffers()) {
      return false;
    }

    if (this.meshState.prevPrevMesh) {
      this.meshState.prevPrevMesh.uncompressedPos = null;
    }

    this.meshState.prevPrevMesh = this.meshState.prevMesh;
    this.meshState.prevMesh = this.meshState.curMesh;
    this.meshState.curMesh = frame;

    var sourceBuffers: {
      indices: Uint16Array | Uint32Array | null;
      compressedPos: Uint16Array | null;
      compressedUVs: Uint16Array | null;
      compressedNormals: Uint8Array | Uint16Array | null;
      deltas: Uint8Array | null;
    } = {
      indices: null,
      compressedPos: null,
      compressedUVs: null,
      compressedNormals: null,
      deltas: null,
    };

    var buffers = this.json.buffers;
    var bufferViews = this.json.bufferViews;

    var attributes = frame.primitives[0].extensions[_extName].attributes;
    var arrayBufferIndex = -1;

    if (attributes.POSITION) {
      arrayBufferIndex = buffers[bufferViews[frame.indices.bufferView].buffer].arrayBufferIndex;
      var indexArrayBuf = this.buffers[arrayBufferIndex]!;
      var posArrayBuf = this.buffers[arrayBufferIndex]!;
      var uvArrayBuf = this.buffers[arrayBufferIndex]!;
      if (frame.indices.componentType == GL_UNSIGNED_SHORT) {
        sourceBuffers.indices = new Uint16Array(indexArrayBuf, bufferViews[frame.indices.bufferView].byteOffset + frame.indices.byteOffset, frame.indices.count);
      } else {
        sourceBuffers.indices = new Uint32Array(indexArrayBuf, bufferViews[frame.indices.bufferView].byteOffset + frame.indices.byteOffset, frame.indices.count);
      }

      this.meshState.lastKeyframeIndices = sourceBuffers.indices;

      sourceBuffers.compressedPos = new Uint16Array(
        posArrayBuf,
        bufferViews[frame.compressedPos.bufferView].byteOffset + frame.compressedPos.byteOffset,
        frame.compressedPos.count * 3
      );
      this.meshState.lastKeyframeUVs = sourceBuffers.compressedUVs = new Uint16Array(
        uvArrayBuf,
        bufferViews[frame.compressedUVs.bufferView].byteOffset + frame.compressedUVs.byteOffset,
        frame.compressedUVs.count * 2
      );
    } else {
      arrayBufferIndex = buffers[bufferViews[frame.deltas.bufferView].buffer].arrayBufferIndex;
      var deltasArrayBuf = this.buffers[arrayBufferIndex]!;
      sourceBuffers.deltas = new Uint8Array(deltasArrayBuf, bufferViews[frame.deltas.bufferView].byteOffset + frame.deltas.byteOffset, frame.deltas.count * 3);
    }

    if (arrayBufferIndex != this.currentBufferIndex) {
      if (this.currentBufferIndex == -1) {
        // we just finished seeking so we didn't just finish reading an old buffer here so don't start downloading a new one.
        this.currentBufferIndex = arrayBufferIndex;
      } else {
        this._logInfo('currentBufferIndex -> ' + arrayBufferIndex);
        // Don't free current buffer if we want to keep it in memory
        if (!this.openOptions.keepAllMeshesInMemory) {
          this.freeArrayBuffers.push(this.currentBufferIndex);
        }
        this.currentBufferIndex = arrayBufferIndex;
        if (!this.pendingBufferDownload) {
          this._loadNextBuffer();
        }
      }
    }

    if (frame.compressedNormals != null) {
      var norArrayBuf = this.buffers[buffers[bufferViews[frame.compressedNormals.bufferView].buffer].arrayBufferIndex]!;

      // oct encoding
      if (frame.compressedNormals.type == 'VEC2') {
        sourceBuffers.compressedNormals = new Uint8Array(
          norArrayBuf,
          bufferViews[frame.compressedNormals.bufferView].byteOffset + frame.compressedNormals.byteOffset,
          frame.compressedNormals.count * 2
        );
      }
      // quantized 16-bit xyz
      else if (frame.compressedNormals.type == 'VEC3') {
        sourceBuffers.compressedNormals = new Uint16Array(
          norArrayBuf,
          bufferViews[frame.compressedNormals.bufferView].byteOffset + frame.compressedNormals.byteOffset,
          frame.compressedNormals.count * 3
        );
      }
    }

    if (!this.graphicsBackend.updateMeshFromCompressedBuffers(this.meshState as MeshStateWebGL, sourceBuffers, updateClientBuffers, wasSeeking)) {
      // keyframe
      if (frame.primitives[0].extensions[_extName].attributes.POSITION) {
        const count = frame.compressedPos.count;

        frame.uncompressedPos = new Float32Array(count * 3); // need to keep these around to decode next frame.

        var min = frame.compressedPos.extensions[_extName].decodeMin;
        var max = frame.compressedPos.extensions[_extName].decodeMax;

        var bboxdx = (max[0] - min[0]) / 65535.0;
        var bboxdy = (max[1] - min[1]) / 65535.0;
        var bboxdz = (max[2] - min[2]) / 65535.0;
        for (var i = 0; i < count; ++i) {
          var i0 = 3 * i;
          var i1 = i0 + 1;
          var i2 = i0 + 2;
          frame.uncompressedPos[i0] = sourceBuffers.compressedPos![i0] * bboxdx + min[0];
          frame.uncompressedPos[i1] = sourceBuffers.compressedPos![i1] * bboxdy + min[1];
          frame.uncompressedPos[i2] = sourceBuffers.compressedPos![i2] * bboxdz + min[2];
        }

        if (updateClientBuffers) {
          this.graphicsBackend.updateClientBuffers(sourceBuffers.indices!, frame.uncompressedPos, sourceBuffers.compressedUVs!);
        }
      } else if (this.meshState.prevMesh) {
        var count = frame.deltas.count;

        frame.uncompressedPos = new Float32Array(count * 3);

        var min = frame.deltas.extensions[_extName].decodeMin;
        var max = frame.deltas.extensions[_extName].decodeMax;
        var bboxdx = (max[0] - min[0]) / 255.0;
        var bboxdy = (max[1] - min[1]) / 255.0;
        var bboxdz = (max[2] - min[2]) / 255.0;

        var deltas = sourceBuffers.deltas!;

        if (this.meshState.prevPrevMesh == null) {
          for (var i = 0; i < count; ++i) {
            var i0 = 3 * i;
            var i1 = i0 + 1;
            var i2 = i0 + 2;

            var x = this.meshState.prevMesh.uncompressedPos![i0];
            var y = this.meshState.prevMesh.uncompressedPos![i1];
            var z = this.meshState.prevMesh.uncompressedPos![i2];

            var deltaX = deltas[i0] * bboxdx + min[0];
            var deltaY = deltas[i1] * bboxdy + min[1];
            var deltaZ = deltas[i2] * bboxdz + min[2];

            // final
            x += deltaX;
            y += deltaY;
            z += deltaZ;

            frame.uncompressedPos[i0] = x;
            frame.uncompressedPos[i1] = y;
            frame.uncompressedPos[i2] = z;
          }
        } else {
          for (var i = 0; i < count; ++i) {
            var i0 = 3 * i;
            var i1 = i0 + 1;
            var i2 = i0 + 2;

            var x = this.meshState.prevMesh.uncompressedPos![i0];
            var y = this.meshState.prevMesh.uncompressedPos![i1];
            var z = this.meshState.prevMesh.uncompressedPos![i2];

            var dx = x - this.meshState.prevPrevMesh.uncompressedPos![i0];
            var dy = y - this.meshState.prevPrevMesh.uncompressedPos![i1];
            var dz = z - this.meshState.prevPrevMesh.uncompressedPos![i2];

            // predicted
            x += dx;
            y += dy;
            z += dz;

            var deltaX = deltas[i0] * bboxdx + min[0];
            var deltaY = deltas[i1] * bboxdy + min[1];
            var deltaZ = deltas[i2] * bboxdz + min[2];

            // final
            x += deltaX;
            y += deltaY;
            z += deltaZ;

            frame.uncompressedPos[i0] = x;
            frame.uncompressedPos[i1] = y;
            frame.uncompressedPos[i2] = z;
          }
        }

        if (updateClientBuffers) {
          this.graphicsBackend.updateClientBuffers(
            wasSeeking ? this.meshState.lastKeyframeIndices! : undefined,
            frame.uncompressedPos,
            wasSeeking ? this.meshState.lastKeyframeUVs! : undefined
          );
        }
      }

      // copy normals, if any
      if (this.fileInfo.haveNormals && sourceBuffers.compressedNormals && updateClientBuffers) {
        /*
        _OctDecode(vec2 f)
        {
            f = f * 2.0 - 1.0;
 
            // https://twitter.com/Stubbesaurus/status/937994790553227264
            vec3 n = vec3( f.x, f.y, 1.0 - abs(f.x) - abs(f.y));
            float t = clamp(-n.z, 0.0, 1.0);
            n.x += n.x >= 0.0 ? -t : t;
            n.y += n.y >= 0.0 ? -t : t;
            return normalize(n);
        }
        */

        if (this.fileInfo.octEncodedNormals) {
          const count = sourceBuffers.compressedNormals.length / 2;
          var uncompressedNormals = new Float32Array(3 * count);
          var abs = Math.abs;
          for (var i = 0; i < count; ++i) {
            let x = sourceBuffers.compressedNormals[2 * i];
            let y = sourceBuffers.compressedNormals[2 * i + 1];
            x = -1.0 + x * 0.0078125;
            y = -1.0 + y * 0.0078125;
            let z = 1.0 - abs(x) - abs(y);
            var t = clamp(-z, 0.0, 1.0);
            x += x >= 0.0 ? -t : t;
            y += y >= 0.0 ? -t : t;
            var invLen = 1.0 / Math.sqrt(x * x + y * y + z * z);
            uncompressedNormals[3 * i] = x * invLen;
            uncompressedNormals[3 * i + 1] = y * invLen;
            uncompressedNormals[3 * i + 2] = z * invLen;
          }
          this.graphicsBackend.updateClientBuffers(undefined, undefined, undefined, uncompressedNormals);
        } else {
          this.graphicsBackend.updateClientBuffers(undefined, undefined, undefined, sourceBuffers.compressedNormals);
        }
      }
    }

    if (frame.primitives[0].extensions[_extName].attributes.POSITION) {
      this.lastKeyframe = this.frameIndex;

      if (this.meshState.prevMesh) {
        this.meshState.prevMesh.uncompressedPos = null;
        this.meshState.prevMesh = null;
      }

      if (this.meshState.prevPrevMesh) {
        this.meshState.prevPrevMesh.uncompressedPos = null;
        this.meshState.prevPrevMesh = null;
      }

      frame.indexCount = frame.indices.count;

      var min = frame.compressedPos.extensions[_extName].decodeMin;
      var max = frame.compressedPos.extensions[_extName].decodeMax;

      this.currentFrameInfo.bboxMin = min;
      this.currentFrameInfo.bboxMax = max;
    } else {
      frame.indexCount = this.meshState.prevMesh!.indexCount;
    }

    return true;
  }

  private _setupMeshFrames() {
    const json = this.json;
    const accessors = json.accessors;
    const numFrames = json.meshes.length;
    const arrayBuffers = this.buffers;
    const hvo = this;

    const ensureBuffers = function (this: Mesh) {
      var bufferViews = json.bufferViews;
      var buffers = json.buffers;

      if (this.primitives[0].extensions[_extName].attributes.POSITION) {
        var indexBufferView = bufferViews[this.indices.bufferView];
        if (
          buffers[indexBufferView.buffer].arrayBufferIndex == undefined ||
          arrayBuffers[buffers[indexBufferView.buffer].arrayBufferIndex]!.bufferIndex != indexBufferView.buffer
        ) {
          hvo._logInfo('buffer for frame ' + this.frameIndex + ' not downloaded yet: ' + buffers[indexBufferView.buffer].uri);
          return false;
        }

        var posBufferView = bufferViews[this.compressedPos.bufferView];
        if (
          buffers[posBufferView.buffer].arrayBufferIndex == undefined ||
          arrayBuffers[buffers[posBufferView.buffer].arrayBufferIndex]!.bufferIndex != posBufferView.buffer
        ) {
          hvo._logInfo('buffer for frame ' + this.frameIndex + ' not downloaded yet: ' + buffers[posBufferView.buffer].uri);
          return false;
        }

        var uvBufferView = bufferViews[this.compressedUVs.bufferView];
        if (buffers[uvBufferView.buffer].arrayBufferIndex == undefined || arrayBuffers[buffers[uvBufferView.buffer].arrayBufferIndex]!.bufferIndex != uvBufferView.buffer) {
          hvo._logInfo('buffer for frame ' + this.frameIndex + ' not downloaded yet: ' + buffers[uvBufferView.buffer].uri);
          return false;
        }
      } else {
        var deltaBufferView = bufferViews[this.deltas.bufferView];
        if (
          buffers[deltaBufferView.buffer].arrayBufferIndex == undefined ||
          arrayBuffers[buffers[deltaBufferView.buffer].arrayBufferIndex]!.bufferIndex != deltaBufferView.buffer
        ) {
          hvo._logInfo('buffer for frame ' + this.frameIndex + ' not downloaded yet: ' + buffers[deltaBufferView.buffer].uri);
          return false;
        }
      }

      if (this.compressedNormals) {
        var norBufferView = bufferViews[this.compressedNormals.bufferView];
        if (
          buffers[norBufferView.buffer].arrayBufferIndex == undefined ||
          arrayBuffers[buffers[norBufferView.buffer].arrayBufferIndex]!.bufferIndex != norBufferView.buffer
        ) {
          hvo._logInfo('buffer for frame ' + this.frameIndex + ' not downloaded yet: ' + buffers[norBufferView.buffer].uri);
          return false;
        }
      }

      return true;
    };

    for (var i = 0; i < numFrames; ++i) {
      var meshFrame = this.json.meshes[i];
      meshFrame.frameIndex = i;
      meshFrame.ensureBuffers = ensureBuffers;

      var attributes = meshFrame.primitives[0].extensions[_extName].attributes;

      if (attributes.POSITION) {
        // accessor offset is relative to bufferView, not buffer
        meshFrame.indices = accessors[meshFrame.primitives[0].extensions[_extName].indices];
        meshFrame.compressedUVs = accessors[attributes.TEXCOORD_0!];
        meshFrame.compressedPos = accessors[attributes.POSITION];
      } else {
        meshFrame.deltas = accessors[attributes._DELTA!];
      }

      if (attributes.NORMAL != null) {
        this.fileInfo.haveNormals = true;
        meshFrame.compressedNormals = accessors[attributes.NORMAL];

        if (meshFrame.compressedNormals.type == 'VEC2') {
          this.fileInfo.octEncodedNormals = true;
        }
      }

      this.meshFrames.push(meshFrame);
    }
  }

  private _onJsonLoaded(response: string): void {
    this._logInfo('got json');

    this.json = JSON.parse(response);

    if (this.openOptions.keepAllMeshesInMemory) {
      this.openOptions.maxBuffers = this.json.buffers.length - 1;
    }

    this.minBuffers = Math.min(this.openOptions.minBuffers!, this.json.buffers.length - 1);
    var timeline = this.json.extensions[_extName].timeline;
    this.minVideos = Math.min(2, timeline.length);

    this.buffers = [null, null, null];

    // reuse this if we can - avoid requirement for additional user interaction for audio
    this.videoElement = this.videoElement ?? document.createElement('video');

    this.videoElement.setAttribute('playsinline', 'playsinline');

    this.videoElement.volume = this.audioVolume;

    for (var i = 0; i < Math.min(this.openOptions.maxBuffers!, this.json.buffers.length - 1); ++i) {
      this.freeArrayBuffers.push(i);
    }

    if (this.openOptions.startTime) {
      this._setSeekTarget(this.openOptions.startTime);
      this.seekingAutoPlay = true; // so 'startPlaybackIfReady' will initiate playback
      this.pauseAfterSeek = true;
    }

    this._setupMeshFrames();

    this._loadNextVideo();

    if (this.seekTargetTime) {
      let frame = this.meshFrames[this.searchStartFrame!];
      const bufferViews = this.json.bufferViews;
      this.seekingStartBufferIndex = bufferViews[frame.indices.bufferView].buffer;
      this._loadNextBuffer();
    } else {
      this._loadNextBuffer();
    }

    this.currentBufferIndex = 0; // this is index into our ring of 3 buffers we keep in memory at a time, not full capture buffers list

    var image = this.json.images[timeline[0].image].extensions[_extName];

    this.fileInfo.videoWidth = image.width;
    this.fileInfo.videoHeight = image.height;

    var ext = this.json.extensions[_extName];

    // first frame is static preview frame
    const numFrames = this.json.meshes.length - 1;
    this.fileInfo.frameCount = numFrames;
    this.fileInfo.duration = (1000 * numFrames) / ext.framerate;

    this.fileInfo.maxVertexCount = ext.maxVertexCount;
    this.fileInfo.maxIndexCount = ext.maxIndexCount;

    this.fileInfo.boundingBox = {
      min: ext.boundingMin,
      max: ext.boundingMax,
    };

    this.fileInfo.indicesComponentType = this.meshFrames[0].indices.componentType;

    this.graphicsBackend.onLoaded(this.fileInfo);

    if (this.onLoaded) {
      this.onLoaded(this.fileInfo);
    }
  }

  private _getChromeVersion(): number | false {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return raw ? parseInt(raw[2], 10) : false;
  }

  private _getIOSVersion(): Version | false {
    var agent = window.navigator.userAgent;
    if (agent.indexOf('iPhone') > 0 || agent.indexOf('iPad') > 0 || agent.indexOf('iPod') > 0) {
      var raw = agent.match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (raw) {
        return {
          major: parseInt(raw[1] || '0', 10),
          minor: parseInt(raw[2] || '0', 10),
          patch: parseInt(raw[3] || '0', 10),
        };
      }
    }
    return false;
  }

  private _getSafariVersion(): Version | false {
    var agent = window.navigator.userAgent;
    var raw = agent.match(/Version\/(\d+).(\d+).?(\d+)?/);
    if (raw) {
      return {
        major: parseInt(raw[1] || '0', 10),
        minor: parseInt(raw[2] || '0', 10),
        patch: parseInt(raw[3] || '0', 10),
      };
    }
    return false;
  }

  // public APIs begin here:

  public _logDebug(message: string, force?: boolean) {
    if (this.logLevel >= 3) {
      var id = this.id;
      console.log(`[${id}] ` + message);
    }
  }

  public _logInfo(message: string, force?: boolean) {
    if (this.logLevel >= 2 || force) {
      var id = this.id;
      console.log(`[${id}] ` + message);
    }
  }

  public _logWarning(message: string) {
    if (this.logLevel >= 1) {
      var id = this.id;
      console.log(`[${id}] ` + message);
    }
  }

  public _logError(message: string) {
    if (this.logLevel >= 0) {
      var id = this.id;
      console.log(`[${id}] ` + message);
    }
  }

  private _onError(type: ErrorStates, info?: Error | MediaError) {
    if (this.errorCallback) {
      this.errorCallback(type, info);
    }
  }

  public onContextLost(): void {
    this.contextLost = true;
    this.wasPlaying = this.state == States.Playing;
    this.pause();
    this._logInfo('contextlost -> pausing playback');
  }

  public onContextRestored(): void {
    this.contextLost = false;

    this.updateToLastKeyframe();

    if (this.wasPlaying) {
      this.wasPlaying = false;
      this._logInfo('contextrestored -> resuming playback');
      this.play();
    }
  }

  /**
   * @callback HoloVideoObject~errorCallback
   * @param {HoloVideoObject.ErrorStates} error type - Value from {@link HoloVideoObject#ErrorStates} enum indicating the type of error encountered.
   * @param {Object} additional error information (see description of specific {@link HoloVideoObject#ErrorStates} value for more information).
   */

  /**
   * {@link HoloVideoObject} is the internal web player implementation that interacts directly with WebGL (independent of three.js).
   * {@link HoloVideoObjectThreeJS} defines the public interface for three.js development.
   */
  constructor(
    context: TContext,
    createOptions?: CreateOptions,
    errorCallback?: (type: ErrorStates, info?: Error | MediaError) => void
  ) {
    const makeBackend = () => {
      if (context instanceof WebGLRenderingContext) {
        return new WebGlBackend(context);
      }

      if (context instanceof WebGL2RenderingContext) {
        return new WebGlBackend(context);
      }

      if (context instanceof GPUCanvasContext) {
        return new WebGpuBackend(context);
      }
    };

    this.id = HoloVideoObject._instanceCounter++;
    this.state = States.Empty;
    this.suspended = false;
    this.graphicsBackend = makeBackend();
    this.errorCallback = errorCallback;
    this.meshState = {
      lastKeyframeIndices: null,
      lastKeyframeUVs: null,
      curMesh: null,
      prevMesh: null,
      prevPrevMesh: null,
    };

    //var ua = window.navigator.userAgent;
    //var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

    this.browserInfo = {
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      safariVersion: this._getSafariVersion(),
      iOSVersion: this._getIOSVersion(),
      isMozillaWebXRViewer: false,
    };

    this.browserInfo.isMozillaWebXRViewer = this.browserInfo.iOSVersion && navigator.userAgent.includes('WebXRViewer');

    if (navigator.userAgent.includes('Mobile') && navigator.platform != 'iPhone' && navigator.platform != 'iPad' && navigator.platform != 'iPod') {
      this.browserInfo.isSafari = false;
    }

    //var webkit = !!ua.match(/WebKit/i);
    //var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    //var isFirefox = typeof InstallTrigger !== 'undefined';

    if (!this.graphicsBackend.initialize(this, this.onContextLost, this.onContextRestored, this.browserInfo, createOptions)) {
      throw 'Catastrophic failure while initializing the graphic backend!';
    }

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        if (this.state == States.Playing) {
          this.wasPlaying = true;
          this._logInfo('document hidden -> pausing playback');
          this.pause();
        } else {
          this.wasPlaying = false;
        }
      } else if (this.wasPlaying) {
        this.wasPlaying = false;
        this._logInfo('document visible -> resuming playback');
        this.play();
      }
    });

    console.log('HoloVideoObject version ' + HoloVideoObject.Version.String);
  }

  getLoadProgress(): number {
    if (this.minBuffers == undefined) {
      return 0;
    }

    if (this.state >= States.Opened) {
      return 1.0;
    }

    return (this.buffersLoaded + this.videosLoaded) / (this.minBuffers + this.minVideos);
  }

  updateToLastKeyframe(): void {
    if (this.lastKeyframe != -1) {
      this.frameIndex = this.lastKeyframe - 1;
      this.meshState.curMesh = null;
      this.meshState.prevMesh = null;
      this.meshState.prevPrevMesh = null;
      //this._updateMesh(this.clientBuffers.posBuf, this.clientBuffers.uvBuf, this.clientBuffers.indexBuf, this.clientBuffers.norBuf);
    }
  }

  private _loadFallbackFrame() {
    if (this.json && this.fallbackFrameBuffer) {
      if (!this.fallbackTextureImage) {
        this.fallbackTextureImage = new Image();

        var encode = function (input: Uint8Array) {
          var keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
          var output = '';
          var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
          var i = 0;

          while (i < input.length) {
            chr1 = input[i++];
            chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
            chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
              enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
              enc4 = 64;
            }
            output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
          }
          return output;
        };

        // FIXME? can we always assume fallback image is image 0?
        var fallbackImage = this.json.images[0];
        var bufferView = this.json.bufferViews[fallbackImage.bufferView];

        this.fallbackTextureImage.src = 'data:image/jpeg;base64,' + encode(new Uint8Array(this.fallbackFrameBuffer, bufferView.byteOffset, bufferView.byteLength));

        this.fallbackTextureImage.onload = () => {
          this._logInfo('fallback image loaded');
          this.fallbackTextureImage!.loaded = true;
        };
      }

      if (this.fallbackTextureImage && this.fallbackTextureImage.loaded && !this.filledFallbackFrame) {
        var fallbackPrim = this.json.meshes[0].primitives[0];

        var posAccessor = this.json.accessors[fallbackPrim.attributes.POSITION];
        var posBufferView = this.json.bufferViews[posAccessor.bufferView];
        const posData = new Float32Array(this.fallbackFrameBuffer, posBufferView.byteOffset + posAccessor.byteOffset, posAccessor.count * 3);

        let norData: Float32Array | undefined = undefined;
        if (this.fileInfo.haveNormals) {
          var norAccesor = this.json.accessors[fallbackPrim.attributes.NORMAL];
          var norBufferView = this.json.bufferViews[norAccesor.bufferView];
          norData = new Float32Array(this.fallbackFrameBuffer, norBufferView.byteOffset + norAccesor.byteOffset, norAccesor.count * 3);
        }

        var uvAccesor = this.json.accessors[fallbackPrim.attributes.TEXCOORD_0];
        var uvBufferView = this.json.bufferViews[uvAccesor.bufferView];
        const uvData = new Uint16Array(this.fallbackFrameBuffer, uvBufferView.byteOffset + uvAccesor.byteOffset, uvAccesor.count * 2);

        var indexAccessor = this.json.accessors[fallbackPrim.indices];
        var indexBufferView = this.json.bufferViews[indexAccessor.bufferView];

        const arrayConstructor = indexAccessor.componentType === GL_UNSIGNED_SHORT ? Uint16Array : Uint32Array;
        const indexData = new arrayConstructor(this.fallbackFrameBuffer, indexBufferView.byteOffset + indexAccessor.byteOffset, indexAccessor.count);

        this.graphicsBackend.updateClientBuffers(indexData, posData, uvData, norData, true);
        this.graphicsBackend.setTexture(this.fallbackTextureImage);

        this.currentFrameInfo.primCount = indexAccessor.count;
        this.currentFrameInfo.indexCount = indexAccessor.count;
        this.currentFrameInfo.vertexCount = posAccessor.count;

        posAccessor = this.json.accessors[fallbackPrim.extensions[_extName].attributes.POSITION!];
        var min = posAccessor.extensions[_extName].decodeMin;
        var max = posAccessor.extensions[_extName].decodeMax;
        this.currentFrameInfo.bboxMin = min;
        this.currentFrameInfo.bboxMax = max;

        this.filledFallbackFrame = true;
        // keeping these around for rewind:
        //this.fallbackTextureImage = null;
        //this.fallbackFrameBuffer = null;
      }

      return this.filledFallbackFrame;
    }

    return false;
  }

  updateBuffers(): boolean {
    if (this.contextLost) {
      return false;
    }

    if (!this.json) {
      return false;
    }

    // seekTargetTime may not be the best flag here, but something that tells us if we intentionally skipped loading the fallback frame
    if (!this.filledFallbackFrame && !this.seekTargetTime) {
      const updated = this._loadFallbackFrame();
      this.onUpdate?.(updated, this.currentFrameInfo);
      return updated;
    }

    const timeline = this.json.extensions[_extName].timeline;
    const image = this.json.images[timeline[0].image];
    const currentVideo = image.video;

    //if (!this.needMeshData &&
    //   currentVideo && 
    //   currentVideo.playing && 
    //   this.suspended && currentVideo.readyState == 4) {
    //  this._logInfo("updateBuffers resuming stalled video");
    //  currentVideo.play();
    //  this.suspended = false;
    //}

    let forceEndOfStream = false;

    if (currentVideo && currentVideo.playing && !this.suspended) {
      // When video is playing its last few frames it can reach readyState < 4 and not be "stalled".
      //if (currentVideo.readyState != 4) {
      //  this._logInfo("suspending currentVideo.readyState -> " + currentVideo.readyState)
      //  currentVideo.pause();
      //  this.suspended = true;
      //}

      var now = window.performance.now();
      var videoNow = currentVideo.currentTime * 1000;

      if (now - this.lastUpdate < 20.0) {
        return false;
      }

      //this._logInfo("update time since last update = " + (now - this.lastUpdate));
      //this._logInfo("video time since last update = " + (videoNow - this.lastVideoTime));
      this.lastVideoTime = videoNow;
      this.lastUpdate = now;

      var videoSampleIndex = -1;

      const [watermarkPixels, dontCheckForInvalidFrame] = this.graphicsBackend.getWatermark(currentVideo);

      if (watermarkPixels) {
        var blockSize = image.extensions[_extName].blockSize * 4;
        videoSampleIndex = 0;
        for (var i = 0; i < 16; ++i) {
          if (watermarkPixels[blockSize * i + 0] > 128 || watermarkPixels[blockSize * i + 4] > 128) {
            videoSampleIndex += 1 << i;
          }
        }

        var allBlack = true;
        // video frame jumped back to 0, this could be a loop, or a black/invalid frame.
        // scan the whole watermark row and if every pixel is black assume it's an invalid frame.
        if (!dontCheckForInvalidFrame && videoSampleIndex == 0 && videoSampleIndex < this.lastVideoSampleIndex) {
          for (var i = 0; i < watermarkPixels.byteLength; ++i) {
            if (watermarkPixels[i] != 0) {
              allBlack = false;
              break;
            }
          }

          if (allBlack) {
            this._logWarning('dropping empty/black video frame');
            this.currentFrameInfo.primCount = 0;
            this.onUpdate?.(true, this.currentFrameInfo);
            return true;
          }
        }
      }

      // if (videoSampleIndex < this.lastVideoSampleIndex) {
      //   console.log("video loop detected");
      // }

      if (videoSampleIndex > -1 && videoSampleIndex != this.oldVideoSampleIndex) {
        let readMesh = true;

        if (this.seeking && this.requestKeyframe) {
          if (this._frameIsKeyframe(videoSampleIndex)) {
            this.requestKeyframe = false;
            this.frameIndex = videoSampleIndex - 1; // so _updateMesh can "advance" to frame number 'videoSampleIndex'
            this._logDebug('seeking found keyframe -> ' + videoSampleIndex);
          } else {
            this._logDebug('seeking wait for keyframe -> ' + videoSampleIndex);
            readMesh = false;
          }
        }

        if (readMesh && (this.meshState.curMesh == null || this.meshState.curMesh.frameIndex != videoSampleIndex)) {
          let wasSeeking = this.seeking;

          if (this.seeking) {
            const ext = this.json.extensions[_extName];
            let frameTimestamp = videoSampleIndex / ext.framerate;

            if (frameTimestamp < this.seekTargetTime!) {
              // nothing to do here?
            } else {
              this.seeking = false;
              this._logDebug('seeking finished at frame ' + videoSampleIndex);
              if (this.unmuteAfterSeek) {
                currentVideo.muted = false;
              }
            }
          }

          // only auto-pause *after* startPlaybackIfReady has taken care of seekingAutoPlay flag,
          // or we can end up restarting playback after auto-pause here.
          if (!this.seeking && this.pauseAfterSeek && !this.seekingAutoPlay) {
            this.pause();
            currentVideo.playing = false;
            delete this.pauseAfterSeek;
          }

          //this._logDebug("videoSampleIndex -> " + videoSampleIndex);

          const updateClientBuffers = !this.seeking;

          if (this.meshFrames[videoSampleIndex].ensureBuffers()) {
            if (videoSampleIndex < this.lastVideoSampleIndex) {
              this.frameIndex = -1;
              this._updateMesh(updateClientBuffers, wasSeeking);
              this._logInfo('loop detected, videoSampleIndex = ' + videoSampleIndex + ', curMesh.frameIndex = ' + this.meshState.curMesh?.frameIndex);
            }

            while (this.meshState.curMesh == null || this.meshState.curMesh.frameIndex < videoSampleIndex) {
              if (!this._updateMesh(updateClientBuffers, wasSeeking)) {
                break;
              }
            }

            this._logDebug('updated to frame index = ' + videoSampleIndex);

            // Don't update texture unless we were able to update mesh to target frame (the only reason this should ever be possible is if the mesh data isn't downloaded yet)
            // Note that we're not stopping the video -> texture -> pbo -> watermark loop from continuing, not sure if this matters?
            if (this.meshState.curMesh?.frameIndex == videoSampleIndex && updateClientBuffers) {
              this.graphicsBackend.copyVideoTexture();
            }

            if (this.meshState.curMesh && this.meshState.curMesh.frameIndex != videoSampleIndex) {
              this._logInfo('texture (' + videoSampleIndex + ') <-> mesh (' + this.meshState.curMesh.frameIndex + ') mismatch');
            }

            this.lastVideoSampleIndex = videoSampleIndex;
          } else {
            this._logWarning('ran out of mesh data, suspending video ' + currentVideo.mp4Name);
            currentVideo.pause();
            this.suspended = true;
            this.needMeshData = true;
            if (!this.pendingBufferDownload) {
              this._loadNextBuffer();
            }
          }
        } else {
          // We need to force trigger end of stream even when video tag doesn't play last frame for some reason
          if (this.pendingVideoEndEvent && this.state == States.Playing) {
            this.pendingVideoEndEventWaitCount++;
            forceEndOfStream = this.pendingVideoEndEventWaitCount > 3;
          }
        }
      }
    }

    // In async mode, video playback ends before all frames are displayed so we must wait before triggering end-of-video process
    if (this.pendingVideoEndEvent && (this.lastVideoSampleIndex == this.meshFrames.length - 1 || forceEndOfStream)) {
      currentVideo.playing = false;
      this._onVideoEnded(currentVideo);
      this.pendingVideoEndEvent = false;
    }

    if (this.meshState.curMesh && !this.seeking) {
      this.currentFrameInfo.indexCount = this.meshState.curMesh.indexCount;
      this.currentFrameInfo.primCount = this.currentFrameInfo.indexCount;

      this.currentFrameInfo.vertexCount = this.meshState.curMesh.compressedPos?.count ?? this.meshState.curMesh.deltas.count;

      this.currentFrameInfo.frameIndex = this.meshState.curMesh.frameIndex;

      if (this.onUpdateCurrentFrame) {
        this.onUpdateCurrentFrame(this.meshState.curMesh.frameIndex);
      }
      this.onUpdate?.(true, this.currentFrameInfo);

      return true;
    }

    this.onUpdate?.(false, this.currentFrameInfo);

    return false;
  }

  close(): void {
    if (this.httpRequest) {
      this.httpRequest.abort();
      this.httpRequest = null;
    }

    if (this.dashPlayer) {
      this.dashPlayer.reset();
    }

    this.cleanUpEventListeners?.();

    this.videoElement.pause();
    this.videoElement.removeAttribute('src');
    this.videoElement.remove();
    this.videoElementInitialized = false;

    this.state = States.Closed;

    this.graphicsBackend.onClose();
  }

  pause(): void {
    if (this.videoElement) {
      this.videoElement.pause();
      this.state = States.Paused;
    }
  }

  setAudioVolume(volume: number): void {
    this.audioVolume = volume;
    this.videoElement.volume = volume;
  }

  setAutoLooping(loop: boolean): void {
    this.openOptions.autoloop = loop;
    this.videoElement.loop = loop;
  }

  setAudioEnabled(enabled: boolean): void {
    this.videoElement.muted = !enabled;
  }

  audioEnabled(): boolean {
    return !this.videoElement.muted;
  }

  play(): void {
    if (this.browserInfo.isSafari) {
      // Calling this now prevents a stalled video
      // from sometimes failing to play(), causing
      // the hologram to never start playing
      this.videoElement.pause();
    }

    const playPromise = this.videoElement.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          this.state = States.Playing;
        })
        .catch((error: Error) => {
          // Auto-play was prevented.
          this._logWarning(`play prevented ${error}`);
          this._onError(ErrorStates.PlaybackPrevented, error);
        });
    }
  }

  open(gltfURL: string, options: OpenOptions): void {
    if (this.state >= States.Opening) {
      this.close();
    }

    this.state = States.Opening;

    // leave this pointing to parent directory of .gltf file so we can locate .bin, .mp4 files relative to it.
    this.urlRoot = gltfURL.substring(0, gltfURL.lastIndexOf('/') + 1);

    this.meshFrames = [];
    this.buffersLoaded = 0;
    this.videosLoaded = 0;

    // indices into arrays below for next objects we can load data into
    this.freeArrayBuffers = [];

    // owning references on video and buffer objects (max size 3)
    this.buffers = [];

    // next video/buffer to load (ahead of playback position)
    this.nextVideoLoadIndex = 0;
    this.nextBufferLoadIndex = 0;

    this.videoState = VideoStates.Undefined;

    this.currentFrameInfo = {
      primCount: 0,
      indexCount: 0,
      vertexCount: 0,
    };

    // these are current playback positions
    this.currentBufferIndex = -1;

    this.lastVideoTime = 0;
    this.lastUpdate = 0;

    this.json = null as any;
    this.fileInfo = {
      haveNormals: false,
      octEncodedNormals: false,
    };

    if (options) {
      this.openOptions = options;
    } else {
      this.openOptions = {};
    }

    if (!this.openOptions.minBuffers) {
      this.openOptions.minBuffers = 2;
    }

    if (!this.openOptions.maxBuffers) {
      this.openOptions.maxBuffers = 3;
    }

    this.meshState.curMesh = null;
    this.meshState.prevMesh = null;
    this.meshState.prevPrevMesh = null;
    this.frameIndex = -1;
    this.lastKeyframe = -1;
    this.meshState.lastKeyframeIndices = null;
    this.meshState.lastKeyframeUVs = null;
    this.lastVideoSampleIndex = -1;
    this.filledFallbackFrame = false;
    this.fallbackFrameBuffer = null;
    this.fallbackTextureImage = null;
    this.eos = false;
    delete this.seekTargetTime;
    delete this.searchStartFrame;

    this.onBeforeLoad?.(options);
    this.graphicsBackend.onBeforeLoad(options);

    this._loadJSON(gltfURL, this._onJsonLoaded.bind(this));
  }

  getGraphicsBackend() {
    return this.graphicsBackend;
  }
}
