import { States, Context } from './holovideoobject';
import type { HoloVideoObject, ErrorStates } from './holovideoobject';
import type { FileInfo, OpenOptions, CreateOptions } from './holoVideoInterface';

export abstract class HoloVideoFrontEnd<TContext extends Context> {
  public state: States;

  protected _onEndOfStream: any;
  protected _hvo: HoloVideoObject<TContext>;
  protected _fileInfo: FileInfo;

  private _hvoOnEndOfStream(hvo: HoloVideoObject<TContext>) {
    if (this._onEndOfStream) {
      this._onEndOfStream(this);
    }
  }

  public get onUpdateCurrentFrame() {
    return this.hvo.onUpdateCurrentFrame;
  }

  public set onUpdateCurrentFrame(callback: typeof this.hvo.onUpdateCurrentFrame) {
    this.hvo.onUpdateCurrentFrame = callback;
  }

  protected abstract _createHoloVideoObject(
    context: TContext,
    createOptions?: CreateOptions,
    updateCurrentFrameCallback?: (frame: number) => void,
    errorCallback?: (type: ErrorStates, info?: Error | MediaError) => void
  ): HoloVideoObject<TContext>;

  public get hvo() {
    return this._hvo;
  }

  constructor(
    context: TContext,
    callback: (fileInfo: FileInfo) => void,
    createOptions?: CreateOptions,
    updateCurrentFrameCallback?: (frame: number) => void,
    errorCallback?: (type: ErrorStates, info?: Error | MediaError) => void,
  ) {
    const hvo = this._createHoloVideoObject(
      context,
      createOptions,
      updateCurrentFrameCallback,
      errorCallback
    );

    this._hvo = hvo;
    hvo.onEndOfStream = this._hvoOnEndOfStream.bind(this);
    hvo.onUpdateCurrentFrame = updateCurrentFrameCallback;
    hvo.onLoaded = (fileInfo) => {
      this._fileInfo = fileInfo;
      this.state = this._hvo.state;
      callback(fileInfo);
    };
  }

  /**
   * Opens and capture and begins buffering data for playback
   *
   * @param {string} url - Capture URL, e.g. "http://127.0.0.1/mycapture.hcap"
   * @param {Object} options - Optional collection of options related to capture loading and playback.
   * @param {boolean} options.audioEnabled - Specifies whether audio playback is enabled initially
   * @param {boolean} options.autoplay - Specifies whether capture should automatically begin playback when loaded. If enabled will disable audio as many browsers do not allow automatic audio playback without user input.
   * @param {HoloVideoObject.StreamMode} options.streamMode - Force the streamMode for the video object. Default is Automatic.
   * @param {number} options.minBuffers - Minimum number of .bin segments that must be buffered before capture is considered preloaded. Default value is 2.
   * @param {number} options.maxBuffers - Maximum number of .bin segments that can be buffered and kept in memory at one time.
   * @param {boolean} options.keepAllMeshesInMemory - Overrides maxBuffers setting and keeps all mesh in memory to prevent further loading.
   * @param {number} options.startTime - Time in seconds where playback should begin from. Defaults to 0 if not specified.
   * @param {number} options.crossOrigin - Value that will be passed through to the `crossOrigin` propety on the plugin's internal `VideoElement`. The default value is "use-credentials".
   */
  public open(url: string, options: OpenOptions): void {
    if (this.state > States.Empty) {
      this.close();
    }
    this._hvo.open(url, options);
    this.state = this._hvo.state;
  }

  /**
   * Updates capture mesh and texture to latest playback frame. This should be called periodically from the application's animate/update loop.
   */
  public update(): void {
    if (this._hvo) {
      this.state = this._hvo.state;
    }

    this._hvo.updateBuffers();
  }

  /**
   * Rewinds capture back to the first frame and pauses.
   */
  public rewind(): void {
    this._hvo.rewind();
  }

  /**
   * Seeks playback position to the specified time.
   * @param {number} seekTime - Time (in seconds) to seek to.
   * @param {boolean} displayImmediately - Specifies whether to update to and display target frame immediately.
   */
  public seekToTime(seekTime: number, displayImmediately: boolean): void {
    this._hvo.seekToTime(seekTime, displayImmediately);
  }

  /**
   * Starts capture playback if sufficient data has been buffered, or resumes paused playback.
   */
  public play(): void {
    if (this._hvo.state == States.Opening) {
      this._hvo.forceLoad();
    } else if (this._hvo.state >= States.Opened && this._hvo.state != States.Playing) {
      this._hvo.play();
    }
  }

  /**
   * Stops playback and releases capture-specific resources. A new capture can then be loaded by calling {@link HoloVideoFrontEnd#open}
   */
  public close(): void {
    this._hvo.close();
    this.onClose();
  }

  protected onClose(): void {}

  /**
   * Pauses playback.
   */
  public pause(): void {
    this._hvo.pause();
  }

  /**
   * Sets verbosity level of log output
   * @param {number} level - 0 = errors, 1 = warnings, 2 = info, 3 = debug. Default setting is = 0.
   */
  public setLogLevel(level: number): void {
    this._hvo.logLevel = level;
  }

  /**
   * Enables or disables audio playback. May be called at any time.
   * @param {boolean} enabled - Specifies whether audio should be enabled or disabled.
   */
  public setAudioEnabled(enabled: boolean): void {
    this._hvo.setAudioEnabled(enabled);
  }

  /**
   * Returns whether audio playback is currently enabled.
   */
  public audioEnabled(): boolean {
    return this._hvo.audioEnabled();
  }

  /**
   * Sets audio volume.
   * @param {number} volume - Value between 0 and 1 specifying volume level.
   */
  public setAudioVolume(volume: number): void {
    this._hvo.setAudioVolume(volume);
  }

  /**
   * Specifies whether capture should loop automatically. May be called at any time.
   * @param {boolean} loop
   */
  public setAutoLooping(loop: boolean): void {
    this._hvo.setAutoLooping(loop);
  }
}
