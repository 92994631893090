/// <reference types="@webgpu/types" />

import {
  GraphicsBackend,
  Logger,
  BrowserInfo,
  CreateOptions,
  OpenOptions,
  FileInfo,
  MeshState,
} from './holoVideoInterface';

export type WebGpuClientBuffers = {
  posBuf: GPUBuffer;
  norBuf: GPUBuffer;
  uvBuf: GPUBuffer;
  indexBuf: GPUBuffer;
  tex: GPUTexture;
};

export type TypedArrayClientBuffers = {
  posBuf?: Float32Array;
  norBuf?: Uint8Array | Uint16Array | Float32Array;
  uvBuf?: Uint16Array;
  indexBuf?: Uint16Array | Uint32Array;
  tex: GPUTexture;
};

// TODO: Stub.
export class WebGpuBackend implements GraphicsBackend {
  constructor(context: GPUCanvasContext) {}

  initialize(
    logger: Logger,
    onContextLost: () => void,
    onContextRestored: () => void,
    browserInfo: BrowserInfo,
    createOptions?: CreateOptions
  ): boolean {
    // TODO: Returning true for now so that the Babylon js backend
    // can externally replace this one.
    return true;
  }

  release(): void {}

  setBuffers(clientBuffers: WebGpuClientBuffers) {}

  setBuffersFromTypedArrays(clientBuffers: TypedArrayClientBuffers) {}

  onBeforeLoad(openOptions: OpenOptions): void {}

  onLoaded(fileInfo: Partial<FileInfo>): void {}

  onSeekToTimeCompleted(): void {}
  onRewind(): void {}
  onClose(): void {}
  updateClientBuffers(
    indices?: Uint16Array | Uint32Array,
    positions?: Float32Array,
    uvs?: Uint16Array,
    normals?: Uint16Array | Uint8Array | Float32Array,
    staticDraw?: boolean,
  ): void {}

  updateMeshFromCompressedBuffers(
    meshState: MeshState, 
    sourceBuffers: {
      indices: Uint16Array | Uint32Array;
      compressedPos: Uint16Array;
      compressedUVs: Uint16Array;
      compressedNormals: Uint16Array | Uint8Array;
      deltas: Uint8Array;
    },
    updateClientBuffers: boolean,
    wasSeeking: boolean
  ): boolean {
    return false;
  }

  setTexture(image: HTMLImageElement): void {}
  copyVideoTexture(): void {}
  getWatermark(currentVideo: HTMLVideoElement): [Uint8Array, boolean] {
    return [null, false];
  }
}