export const _extName = 'HCAP_holovideo';

export type BrowserInfo = {
  isSafari: boolean;
  safariVersion: { major: number; minor: number; patch: number } | false;
  iOSVersion: { major: number; minor: number; patch: number } | false;
  isMozillaWebXRViewer: boolean;
};

export type Version = {
  major: number;
  minor: number;
  patch: number;
};

/**
 * HoloVideoObject StreamMode - set as an option to HoloVideoObject.open() to force the StreamMode for the video element
 * Use when calling holoVideoObject.Open()
 */
export enum StreamMode {
  Automatic = 0, // Default: Selects based on browser.
  MP4 = 1, // Force load of full mp4 before playing.
  HLS = 2, // Force use of HLS for playback. Usually used only in Safari.
  Dash = 3, // Force use of Dash for segmenting video and faster load. Usually NOT used in Safari. Requires Dash player.
}

export type OpenOptions = {
  autoplay?: boolean;
  keepAllMeshesInMemory?: boolean;
  maxBuffers?: number;
  minBuffers?: number;
  audioEnabled?: boolean;
  autoloop?: boolean;
  streamMode?: StreamMode;
  startTime?: number;
  crossOrigin?: string;
  onReady?: () => void;
};

export type CreateOptions = {
  outputLinearTextures?: boolean;
  useImmutableTexture?: boolean;
};

export type FileInfo = Partial<{
  octEncodedNormals: boolean;
  haveNormals: boolean;
  videoWidth: number;
  videoHeight: number;
  maxVertexCount: number;
  maxIndexCount: number;
  boundingBox: {
    min: [number, number, number];
    max: [number, number, number];
  };
  duration: number;
  frameCount: number;
  indicesComponentType: number;
}>;

export type Extensions = {
  HCAP_holovideo: {
    decodeMin: [number, number, number];
    decodeMax: [number, number, number];
    hlsUri: string;
    width: number;
    height: number;
    dashUri: string;
    blockSize: number;
    timeline: {
      image: number;
      startFrame: number;
      targetNode: number;
    }[];
    framerate: number;
    keyframes: number[];
    indices: number;
    attributes: {
      POSITION: number | null;
      TEXCOORD_0: number | null;
      _DELTA: number | null;
      NORMAL: number | null;
    };
    maxVertexCount: number;
    maxIndexCount: number;
    boundingMin: [number, number, number];
    boundingMax: [number, number, number];
  };
};

export type Accessor = {
  type: 'VEC2' | 'VEC3';
  bufferView: number;
  componentType: number; // GL Enum.
  byteOffset: number;
  count: number;
  extensions: Extensions;
};

export type Mesh = {
  indices: Accessor;
  ensureBuffers: () => boolean;
  primitives: {
    extensions: Extensions;
    attributes: Record<string, any>;
    indices: number;
  }[];
  frameIndex: number;
  indexCount: number;
  compressedPos: Accessor;
  compressedUVs: Accessor;
  compressedNormals: Accessor;
  uncompressedPos: Float32Array | null;
  deltas: Accessor;
};

export type MeshState = {
  lastKeyframeUVs: Uint16Array | null;
  lastKeyframeIndices: Uint16Array | Uint32Array | null;
  prevPrevMesh: Mesh | null;
  prevMesh: Mesh | null;
  curMesh: Mesh | null;
};

export type FrameInfo = {
  bboxMin?: unknown;
  bboxMax?: unknown;
  primCount: number; // Deprecated: use indexCount instead.
  indexCount: number;
  vertexCount: number;
  frameIndex?: number;
};

export interface Logger {
  _logDebug(message: string, force?: boolean): void;
  _logInfo(message: string, force?: boolean): void;
  _logWarning(message: string): void;
  _logError(message: string): void;
}

// Implemented by each graphics back-end (WebGL, Threejs, Babylonjs, ...)
export interface GraphicsBackend {
  initialize(logger: Logger, onContextLost: () => void, onContextRestored: () => void, browserInfo: BrowserInfo, createOptions?: CreateOptions): boolean;
  release(): void;

  onBeforeLoad(openOptions: OpenOptions): void;
  onLoaded(fileInfo: FileInfo): void;
  onSeekToTimeCompleted(): void;
  onRewind(): void;
  onClose(): void;

  updateMeshFromCompressedBuffers(
    meshState: MeshState,
    sourceBuffers: {
      indices: Uint16Array | Uint32Array | null;
      compressedPos: Uint16Array | null;
      compressedUVs: Uint16Array | null;
      compressedNormals: Uint8Array | Uint16Array | null;
      deltas: Uint8Array | null;
    },
    updateClientBuffers: boolean,
    wasSeeking: boolean
  ): boolean;
  updateClientBuffers(
    indices?: Uint16Array | Uint32Array,
    positions?: Float32Array,
    uvs?: Uint16Array,
    normals?: Uint8Array | Uint16Array | Float32Array,
    staticDraw?: boolean,
  ): void;

  setTexture(image: HTMLImageElement): void;
  copyVideoTexture(): void;
  getWatermark(currentVideo: HTMLVideoElement): [Uint8Array | null, boolean];
}
