import {
  _extName,
  GraphicsBackend,
  Logger,
  CreateOptions,
  BrowserInfo,
  FileInfo,
  OpenOptions,
  Mesh,
  MeshState,
  FrameInfo,
} from './holoVideoInterface';

export type CreateOptionsWebGL = CreateOptions & {
  disableAsyncDecode?: boolean;
  numAsyncFrames?: number;
  disableTransformFeedback?: boolean;
};

type MeshWebGL = Mesh & {
  outputBuffer: WebGLBuffer;
};

export type MeshStateWebGL = MeshState & {
  prevPrevMesh: MeshWebGL | null;
  prevMesh: MeshWebGL | null;
  curMesh: MeshWebGL | null;
};

type Program = WebGLProgram & {
  havePrevPosLoc: WebGLUniformLocation;
  havePrevPrevPosLoc: WebGLUniformLocation;
  decodeMinLoc: WebGLUniformLocation;
  decodeMaxLoc: WebGLUniformLocation;
  inQuantizedLoc: number;
  prevPosLoc: number;
  prevPrevPosLoc: number;
  inOctNormalLoc: number;
  vertexAttribLoc: number;
  textureSamplerLoc: WebGLUniformLocation;
};

export type WebGlClientBuffers = {
  posBuf: WebGLBuffer;
  norBuf: WebGLBuffer;
  uvBuf: WebGLBuffer;
  indexBuf: WebGLBuffer;
  tex: WebGLTexture;
};

export type TypedArrayClientBuffers = {
  posBuf?: Float32Array;
  norBuf?: Uint8Array | Uint16Array | Float32Array;
  uvBuf?: Uint16Array;
  indexBuf?: Uint16Array | Uint32Array;
  tex: WebGLTexture;
};

type TexCopyProgram = Program & { useGammaCorrectionLoc: WebGLUniformLocation };

export class WebGlBackend implements GraphicsBackend {
  protected _logger: Logger;
  protected _browserInfo: BrowserInfo;
  protected _fileInfo: FileInfo;
  protected gl: WebGLRenderingContext | WebGL2RenderingContext;
  protected createOptions: CreateOptionsWebGL;

  protected caps: { webgl2: boolean; badTF: boolean; supports32BitIndices: boolean };
  protected capsStr: string;

  protected nextPbo: number;
  protected readPbo: number;
  protected readFences: WebGLSync[];
  protected outputBufferIndex: number;

  protected texCopyVerts: WebGLBuffer;
  protected textures: WebGLTexture[];
  protected texCopyShader: TexCopyProgram | null;
  protected fbo1: WebGLFramebuffer;
  protected fbo2: WebGLFramebuffer;
  protected pixelBuffers: WebGLBuffer[];
  protected octNormalsShader: Program;
  contextRestoring: boolean;
  protected outputBuffers: [WebGLBuffer, WebGLBuffer, WebGLBuffer];
  protected normalsVao: WebGLVertexArrayObject;
  protected normalsTF: WebGLTransformFeedback | null;
  protected vaos: [WebGLVertexArrayObject, WebGLVertexArrayObject, WebGLVertexArrayObject];
  protected transformFeedbacks: [WebGLTransformFeedback, WebGLTransformFeedback, WebGLTransformFeedback];
  protected tfShader: Program;
  protected deltasBuf: WebGLBuffer;
  protected watermarkPixels: Uint8Array;
  protected clientBuffers: WebGlClientBuffers | TypedArrayClientBuffers;

  constructor(context: WebGLRenderingContext | WebGL2RenderingContext) {
    this.gl = context;
  }

  public initialize(
    logger: Logger,
    onContextLost: () => void,
    onContextRestored: () => void,
    browserInfo: BrowserInfo,
    createOptions?: CreateOptionsWebGL,
    handleContextRestore = true
  ): boolean {
    this._logger = logger;

    if (!this.gl) {
      this._logger._logError('The WebGL backend needs the gl context!');
      return false;
    }

    this._browserInfo = browserInfo;
    this.createOptions = {
      disableAsyncDecode: false,
      numAsyncFrames: 3,
      disableTransformFeedback: false,
      ...createOptions,
    };

    if (this.createOptions.numAsyncFrames < 2) {
      this._logger._logWarning('numAsyncFrames must be at least 2 (' + this.createOptions.numAsyncFrames + ' specified)');
      this.createOptions.numAsyncFrames = 2;
    }

    if (handleContextRestore) {
      const canvas = this.gl.canvas;

      canvas.addEventListener(
        'webglcontextlost',
        (event) => {
          event.preventDefault();
          onContextLost();
        },
        false
      );

      canvas.addEventListener(
        'webglcontextrestored',
        (event) => {
          this.contextRestoring = true;

          this._initializeWebGLResources();

          if (this._fileInfo) {
            this.onLoaded(this._fileInfo);
          }

          onContextRestored();
          this.contextRestoring = false;
        },
        false
      );
    }

    this._initializeWebGLResources();

    return true;
  }

  public onBeforeLoad(openOptions: OpenOptions): void {
    this.nextPbo = 0;
    this._deleteReadFences(this.gl);
  }

  public onLoaded(fileInfo: FileInfo): void {
    this._fileInfo = fileInfo;

    this._logger._logInfo(this.capsStr);

    const gl = this.gl;

    gl.bindTexture(gl.TEXTURE_2D, this.clientBuffers.tex);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, fileInfo.videoWidth, fileInfo.videoHeight, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    if (fileInfo.haveNormals) {
      this.clientBuffers.norBuf = gl.createBuffer();
    }

    if (this.outputBuffers) {
      const gl = this.gl as WebGL2RenderingContext;

      var saveVb = gl.getParameter(gl.ARRAY_BUFFER_BINDING);

      for (var i = 0; i < 3; ++i) {
        gl.bindBuffer(gl.ARRAY_BUFFER, this.outputBuffers[i]);
        gl.bufferData(gl.ARRAY_BUFFER, 12 * fileInfo.maxVertexCount, gl.STREAM_COPY);
      }

      gl.bindBuffer(gl.ARRAY_BUFFER, saveVb);
    }
  }

  public onSeekToTimeCompleted(): void {
    this.nextPbo = 0;
  }

  public onRewind(): void {
    this.nextPbo = 0;
    this._deleteReadFences(this.gl);
  }

  public onClose(): void { }

  public release(): void {
    this._releaseWebGLResources(this.gl);
  }

  protected _createProgram(vertexShaderSource: string, fragmentShaderSource: string, preLink: (program: WebGLProgram) => void): WebGLProgram {
    function createShader(source: string, type: number) {
      var shader = gl.createShader(type);
      gl.shaderSource(shader, source);
      gl.compileShader(shader);
      return shader;
    }

    const gl = this.gl;

    var program = gl.createProgram();
    var vshader = createShader(vertexShaderSource, gl.VERTEX_SHADER);
    gl.attachShader(program, vshader);

    var fshader = createShader(fragmentShaderSource, gl.FRAGMENT_SHADER);
    gl.attachShader(program, fshader);

    if (preLink) {
      preLink(program);
    }

    gl.linkProgram(program);

    var log = gl.getProgramInfoLog(program);
    if (log) {
      this._logger._logError(log);
    }

    log = gl.getShaderInfoLog(vshader);
    if (log) {
      this._logger._logError(log);
    }

    log = gl.getShaderInfoLog(fshader);
    if (log) {
      this._logger._logError(log);
    }

    gl.deleteShader(vshader);
    gl.deleteShader(fshader);

    return program;
  }

  protected _initializeWebGLResources() {
    var caps: any = {};

    const gl = this.gl;

    var version = gl.getParameter(gl.VERSION);
    caps.webgl2 = version.indexOf('WebGL 2.') != -1;
    caps.badTF = this.createOptions.disableTransformFeedback ?? false;

    // webgl2 will be enabled by default in Safari 15 but as of now (8/13/21) it seems to be too buggy for us to use.
    if (this._browserInfo.isSafari || this._browserInfo.isMozillaWebXRViewer) {
      caps.webgl2 = false;
    }

    var debugInfo = gl.getExtension('WEBGL_debug_renderer_info');

    if (debugInfo) {
      caps.vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      caps.renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      caps.isSafari = this._browserInfo.isSafari;
      caps.iOSVersion = this._browserInfo.iOSVersion;

      if (caps.renderer.indexOf('Mali') != -1) {
        //var chromeVersion = this._getChromeVersion();
        // if this gets fixed at some point we'd want to check for a minimum chrome/driver version here
        // https://bugs.chromium.org/p/chromium/issues/detail?id=961950#c8
        caps.badTF = true;
      }
    }

    this.capsStr = JSON.stringify(caps, null, 4);

    this.caps = caps;

    this.fbo1 = gl.createFramebuffer();

    if (this.caps.webgl2) {
      this.caps.supports32BitIndices = true;

      if (!this.caps.badTF) {
        this._setupTransformFeedback();
      }

      if (this.createOptions.disableAsyncDecode) {
        this.textures = [null];
      } else {
        this.textures = new Array(this.createOptions.numAsyncFrames);
        this.pixelBuffers = new Array(this.createOptions.numAsyncFrames);
        this.readFences = new Array(this.createOptions.numAsyncFrames);
        this.nextPbo = 0;
      }
    } else {
      this.caps.supports32BitIndices = gl.getExtension('OES_element_index_uint') != null;

      if (!this.caps.supports32BitIndices) {
        this._logger._logWarning("WebGL1: extension 'OES_element_index_uint' not supported, captures w/32-bit index data will not be playable");
      }

      this.textures = [null];
    }

    this.fbo2 = gl.createFramebuffer();

    // Prepare copy tex shader.
    const psSource = `#version 100
        precision highp float;

        uniform lowp sampler2D textureSampler;
        uniform bool useGammaCorrection;

        vec3 toLinear(vec3 color) { return pow(color, vec3(2.2)); }

        varying mediump vec2 uv;
        void main()
        {
            vec3 color = texture2D(textureSampler, uv).xyz;

            vec3 adjustedColor = useGammaCorrection
                ? toLinear(color)
                : color;

            gl_FragColor = vec4(adjustedColor, 1.0);
        }
        `;

    const vsSource = `#version 100
        attribute mediump vec2 pos;
        varying mediump vec2 uv;
        void main()
        {
            uv = (0.5 * pos + vec2(0.5, 0.5));
            gl_Position = vec4(pos, 0.0, 1.0);
        }
        `;

    const texCopyVertexAttribLoc = 0;

    const prelink = (program: WebGLProgram) => {
      gl.bindAttribLocation(program, texCopyVertexAttribLoc, 'pos');
    };

    const texCopyShader = this._createProgram(vsSource, psSource, prelink) as TexCopyProgram;

    texCopyShader.useGammaCorrectionLoc = gl.getUniformLocation(texCopyShader, 'useGammaCorrection');
    texCopyShader.vertexAttribLoc = texCopyVertexAttribLoc;
    texCopyShader.textureSamplerLoc = gl.getUniformLocation(texCopyShader, 'textureSampler');
    this.texCopyShader = texCopyShader;
    this.texCopyShader.vertexAttribLoc = texCopyVertexAttribLoc;

    this.texCopyVerts = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.texCopyVerts);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1.0, 3.0, 3.0, -1.0, -1.0, -1.0]), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    // ---

    var saveTex = gl.getParameter(gl.TEXTURE_BINDING_2D);

    for (var i = 0; i < this.textures.length; ++i) {
      this.textures[i] = gl.createTexture();
      gl.bindTexture(gl.TEXTURE_2D, this.textures[i]);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    }

    gl.bindTexture(gl.TEXTURE_2D, saveTex);

    this.clientBuffers = {
      indexBuf: gl.createBuffer(),
      norBuf: null,
      posBuf: gl.createBuffer(),
      tex: gl.createTexture(),
      uvBuf: gl.createBuffer(),
    };
  }

  protected _deleteReadFences(gl: WebGLRenderingContext | WebGL2RenderingContext): void {
    if (this.readFences) {
      for (var i = 0; i < this.readFences.length; ++i) {
        if (this.readFences[i] && gl instanceof WebGL2RenderingContext) {
          gl.deleteSync(this.readFences[i]);
          this.readFences[i] = null;
        }
      }
    }
  }

  protected _releaseWebGLResources(gl: WebGLRenderingContext | WebGL2RenderingContext) {
    if (this.caps.webgl2 && !this.caps.badTF && gl instanceof WebGL2RenderingContext) {
      gl.deleteBuffer(this.deltasBuf);

      for (var i = 0; i < 3; ++i) {
        gl.deleteBuffer(this.outputBuffers[i]);
        this.outputBuffers[i] = null;
        gl.deleteTransformFeedback(this.transformFeedbacks[i]);
        this.transformFeedbacks[i] = null;
        gl.deleteVertexArray(this.vaos[i]);
        this.vaos[i] = null;
      }

      gl.deleteTransformFeedback(this.normalsTF);
      this.normalsTF = null;
      gl.deleteVertexArray(this.normalsVao);
      this.normalsVao = null;

      gl.deleteProgram(this.tfShader);
      this.tfShader = null;

      gl.deleteProgram(this.octNormalsShader);
      this.octNormalsShader = null;
    }

    if (this.texCopyShader) {
      gl.deleteProgram(this.texCopyShader);
      this.texCopyShader = null;
    }

    if (this.texCopyVerts) {
      gl.deleteBuffer(this.texCopyVerts);
      this.texCopyVerts = null;
    }

    if (this.pixelBuffers) {
      for (var i = 0; i < this.pixelBuffers.length; ++i) {
        gl.deleteBuffer(this.pixelBuffers[i]);
        this.pixelBuffers[i] = null;
      }
    }

    this._deleteReadFences(this.gl);

    this.nextPbo = 0;

    for (var i = 0; i < this.textures.length; ++i) {
      gl.deleteTexture(this.textures[i]);
    }

    if (this.fbo1) {
      gl.deleteFramebuffer(this.fbo1);
      this.fbo1 = null;
    }

    if (this.fbo2) {
      gl.deleteFramebuffer(this.fbo2);
      this.fbo2 = null;
    }

    if (this.clientBuffers.tex) {
      gl.deleteTexture(this.clientBuffers.tex);
    }
    if (this.clientBuffers.posBuf) {
      gl.deleteBuffer(this.clientBuffers.posBuf);
    }
    if (this.clientBuffers.uvBuf) {
      gl.deleteBuffer(this.clientBuffers.uvBuf);
    }
    if (this.clientBuffers.norBuf) {
      gl.deleteBuffer(this.clientBuffers.norBuf);
    }
    if (this.clientBuffers.indexBuf) {
      gl.deleteBuffer(this.clientBuffers.indexBuf);
    }

    this.clientBuffers.tex = null;
    this.clientBuffers.posBuf = null;
    this.clientBuffers.uvBuf = null;
    this.clientBuffers.norBuf = null;
    this.clientBuffers.indexBuf = null;
  }

  protected _setupTransformFeedback() {
    var gl = this.gl as WebGL2RenderingContext;

    this.outputBufferIndex = 0;
    this.deltasBuf = gl.createBuffer();
    this.outputBuffers = [gl.createBuffer(), gl.createBuffer(), gl.createBuffer()];
    this.transformFeedbacks = [gl.createTransformFeedback(), gl.createTransformFeedback(), gl.createTransformFeedback()];
    this.vaos = [gl.createVertexArray(), gl.createVertexArray(), gl.createVertexArray()];

    gl.bindVertexArray(null);

    for (var i = 0; i < 3; ++i) {
      gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, this.transformFeedbacks[i]);
      gl.bindBufferBase(gl.TRANSFORM_FEEDBACK_BUFFER, 0, this.outputBuffers[i]);
    }

    this.normalsVao = gl.createVertexArray();
    //this.decodedNormals = gl.createBuffer();
    this.normalsTF = gl.createTransformFeedback();
    //gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, this.normalsTF);
    //gl.bindBufferBase(gl.TRANSFORM_FEEDBACK_BUFFER, 0, this.decodedNormals);

    gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, null);
    gl.bindBuffer(gl.TRANSFORM_FEEDBACK_BUFFER, null);

    var tfShaderSourcePS = `#version 300 es
            out lowp vec4 fragColor;
            void main()
            {
                fragColor = vec4(0,0,0,0);
            }
            `;

    var tfShaderSourceVS = `#version 300 es
            in vec3 inQuantized;
            in vec3 prevPos;
            in vec3 prevPrevPos;

            uniform vec3 decodeMin;
            uniform vec3 decodeMax;
            uniform int havePrevPos;
            uniform int havePrevPrevPos;

            out vec3 outPos;

            void main()
            {
                if (havePrevPos == 1)
                {
                    vec3 dm = vec3(0.0, 0.0, 0.0);

                    if (havePrevPrevPos == 1)
                    {
                        dm = prevPos - prevPrevPos;
                    }

                    vec3 delta = (decodeMax - decodeMin) * inQuantized + decodeMin;
                    outPos = prevPos + dm + delta;
                }

                else
                {
                    outPos = (decodeMax - decodeMin) * inQuantized + decodeMin;
                }
            }`;

    var tfShader = this._createProgram(tfShaderSourceVS, tfShaderSourcePS, function (program) {
      gl.transformFeedbackVaryings(program, ['outPos'], gl.SEPARATE_ATTRIBS);
    }) as Program;

    tfShader.havePrevPosLoc = gl.getUniformLocation(tfShader, 'havePrevPos');
    tfShader.havePrevPrevPosLoc = gl.getUniformLocation(tfShader, 'havePrevPrevPos');
    tfShader.decodeMinLoc = gl.getUniformLocation(tfShader, 'decodeMin');
    tfShader.decodeMaxLoc = gl.getUniformLocation(tfShader, 'decodeMax');
    tfShader.inQuantizedLoc = gl.getAttribLocation(tfShader, 'inQuantized');
    tfShader.prevPosLoc = gl.getAttribLocation(tfShader, 'prevPos');
    tfShader.prevPrevPosLoc = gl.getAttribLocation(tfShader, 'prevPrevPos');
    this.tfShader = tfShader;

    var octNormalsShaderSourceVS = `#version 300 es
            in vec2 inOctNormal;
            out vec3 outNormal;

            vec3 OctDecode(vec2 f)
            {
                f = f * 2.0 - 1.0;

                // https://twitter.com/Stubbesaurus/status/937994790553227264
                vec3 n = vec3( f.x, f.y, 1.0 - abs(f.x) - abs(f.y));
                float t = clamp(-n.z, 0.0, 1.0);
                n.x += n.x >= 0.0 ? -t : t;
                n.y += n.y >= 0.0 ? -t : t;
                return normalize(n);
            }

            void main()
            {
                outNormal = OctDecode(inOctNormal);
            }`;

    var octNormalsShader = this._createProgram(octNormalsShaderSourceVS, tfShaderSourcePS, function (program) {
      gl.transformFeedbackVaryings(program, ['outNormal'], gl.SEPARATE_ATTRIBS);
    }) as Program;

    octNormalsShader.inOctNormalLoc = gl.getAttribLocation(octNormalsShader, 'inOctNormal');
    this.octNormalsShader = octNormalsShader;
  }

  // Formerly updateMeshTF
  public updateMeshFromCompressedBuffers(
    meshState: MeshStateWebGL,
    sourceBuffers: {
      indices: Uint16Array | Uint32Array | null;
      compressedPos: Uint16Array | null;
      compressedUVs: Uint16Array | null;
      compressedNormals: Uint8Array | Uint16Array | null;
      deltas: Uint8Array | null;
    },
    updateClientBuffers: boolean,
    wasSeeking: boolean
  ): boolean {
    if (!this.caps.webgl2 || this.caps.badTF) {
      return false;
    }

    var gl = this.gl as WebGL2RenderingContext;

    // this is the buffer we're capturing to with transform feedback
    meshState.curMesh.outputBuffer = this.outputBuffers[this.outputBufferIndex];

    var saveVb = gl.getParameter(gl.ARRAY_BUFFER_BINDING);
    var saveIb = gl.getParameter(gl.ELEMENT_ARRAY_BUFFER_BINDING);
    var saveShader = gl.getParameter(gl.CURRENT_PROGRAM);
    var saveVa = gl.getParameter(gl.VERTEX_ARRAY_BINDING);

    gl.useProgram(this.tfShader);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    var vertexCount = 0;
    var tfShader = this.tfShader;

    if (meshState.curMesh.primitives[0].extensions[_extName].attributes.POSITION) {
      if (updateClientBuffers) {
        // copy indices
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.clientBuffers.indexBuf);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, sourceBuffers.indices, gl.STATIC_DRAW);
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, saveIb); //Revert this here - otherwise, we somehow overwrite someone else's indices for their geometry buffer

        // copy uvs
        gl.bindBuffer(gl.ARRAY_BUFFER, this.clientBuffers.uvBuf);
        gl.bufferData(gl.ARRAY_BUFFER, sourceBuffers.compressedUVs, gl.STATIC_DRAW);
      }

      gl.bindVertexArray(this.vaos[0]);

      vertexCount = meshState.curMesh.compressedPos.count;

      // copy compressed (quantized) positions
      gl.bindBuffer(gl.ARRAY_BUFFER, this.deltasBuf);
      gl.bufferData(gl.ARRAY_BUFFER, sourceBuffers.compressedPos, gl.DYNAMIC_DRAW);
      gl.enableVertexAttribArray(tfShader.inQuantizedLoc);
      gl.vertexAttribPointer(tfShader.inQuantizedLoc, 3, meshState.curMesh.compressedPos.componentType, true, 0, 0);

      gl.disableVertexAttribArray(tfShader.prevPosLoc);
      gl.disableVertexAttribArray(tfShader.prevPrevPosLoc);

      var min = meshState.curMesh.compressedPos.extensions[_extName].decodeMin;
      var max = meshState.curMesh.compressedPos.extensions[_extName].decodeMax;

      gl.uniform3fv(tfShader.decodeMinLoc, min);
      gl.uniform3fv(tfShader.decodeMaxLoc, max);

      gl.uniform1i(tfShader.havePrevPosLoc, 0);
      gl.uniform1i(tfShader.havePrevPrevPosLoc, 0);
    } else {
      vertexCount = meshState.curMesh.deltas.count;

      if (meshState.prevPrevMesh == null) {
        gl.bindVertexArray(this.vaos[1]);
      } else {
        gl.bindVertexArray(this.vaos[2]);
      }

      gl.bindBuffer(gl.ARRAY_BUFFER, this.deltasBuf);
      gl.bufferData(gl.ARRAY_BUFFER, sourceBuffers.deltas, gl.DYNAMIC_DRAW);
      gl.enableVertexAttribArray(tfShader.inQuantizedLoc);
      gl.vertexAttribPointer(tfShader.inQuantizedLoc, 3, meshState.curMesh.deltas.componentType, true, 0, 0);

      gl.uniform3fv(tfShader.decodeMinLoc, meshState.curMesh.deltas.extensions[_extName].decodeMin);
      gl.uniform3fv(tfShader.decodeMaxLoc, meshState.curMesh.deltas.extensions[_extName].decodeMax);

      gl.uniform1i(tfShader.havePrevPosLoc, 1);

      gl.bindBuffer(gl.ARRAY_BUFFER, meshState.prevMesh.outputBuffer);
      gl.enableVertexAttribArray(tfShader.prevPosLoc);
      gl.vertexAttribPointer(tfShader.prevPosLoc, 3, gl.FLOAT, false, 0, 0);

      if (meshState.prevPrevMesh == null) {
        gl.uniform1i(tfShader.havePrevPrevPosLoc, 0);
        gl.disableVertexAttribArray(tfShader.prevPrevPosLoc);
      } else {
        gl.uniform1i(tfShader.havePrevPrevPosLoc, 1);
        gl.bindBuffer(gl.ARRAY_BUFFER, meshState.prevPrevMesh.outputBuffer);
        gl.enableVertexAttribArray(tfShader.prevPrevPosLoc);
        gl.vertexAttribPointer(tfShader.prevPrevPosLoc, 3, gl.FLOAT, false, 0, 0);
      }
    }

    // ensure output buffer has enough capacity
    var bufferSize = vertexCount * 12;
    gl.bindBuffer(gl.ARRAY_BUFFER, meshState.curMesh.outputBuffer);
    //gl.bufferData(gl.ARRAY_BUFFER, bufferSize, gl.DYNAMIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, this.transformFeedbacks[this.outputBufferIndex]);
    gl.enable(gl.RASTERIZER_DISCARD);
    gl.beginTransformFeedback(gl.POINTS);
    gl.drawArrays(gl.POINTS, 0, vertexCount);
    gl.endTransformFeedback();
    gl.disable(gl.RASTERIZER_DISCARD);
    gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, null);
    gl.bindBuffer(gl.TRANSFORM_FEEDBACK_BUFFER, null);

    //gl.getError();

    // copy captured output into 'posBuf' passed to us by caller.
    if (updateClientBuffers) {
      gl.bindBuffer(gl.COPY_READ_BUFFER, meshState.curMesh.outputBuffer);
      gl.bindBuffer(gl.COPY_WRITE_BUFFER, this.clientBuffers.posBuf);

      gl.bufferData(gl.COPY_WRITE_BUFFER, bufferSize, gl.DYNAMIC_COPY);
      gl.copyBufferSubData(gl.COPY_READ_BUFFER, gl.COPY_WRITE_BUFFER, 0, 0, bufferSize);

      gl.bindBuffer(gl.COPY_READ_BUFFER, null);
      gl.bindBuffer(gl.COPY_WRITE_BUFFER, null);

      if (wasSeeking) {
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.clientBuffers.indexBuf);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, meshState.lastKeyframeIndices, gl.DYNAMIC_DRAW);

        gl.bindBuffer(gl.ARRAY_BUFFER, this.clientBuffers.uvBuf);
        gl.bufferData(gl.ARRAY_BUFFER, meshState.lastKeyframeUVs, gl.DYNAMIC_DRAW);
      }
    }

    this.outputBufferIndex = (this.outputBufferIndex + 1) % 3;

    // copy normals, if any
    if (this.clientBuffers.norBuf && sourceBuffers.compressedNormals && updateClientBuffers) {
      if (this._fileInfo.octEncodedNormals) {
        gl.useProgram(this.octNormalsShader);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);

        gl.bindVertexArray(this.normalsVao);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.deltasBuf); // using deltasBuf as a scratch buffer
        gl.bufferData(gl.ARRAY_BUFFER, sourceBuffers.compressedNormals, gl.DYNAMIC_DRAW);
        gl.enableVertexAttribArray(this.octNormalsShader.inOctNormalLoc);
        gl.vertexAttribPointer(this.octNormalsShader.inOctNormalLoc, 2, gl.UNSIGNED_BYTE, true, 0, 0);

        var bufferSize = vertexCount * 12;
        gl.bindBuffer(gl.ARRAY_BUFFER, this.clientBuffers.norBuf);
        gl.bufferData(gl.ARRAY_BUFFER, bufferSize, gl.DYNAMIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);

        gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, this.normalsTF);
        gl.bindBufferBase(gl.TRANSFORM_FEEDBACK_BUFFER, 0, this.clientBuffers.norBuf);
        gl.enable(gl.RASTERIZER_DISCARD);
        gl.beginTransformFeedback(gl.POINTS);
        gl.drawArrays(gl.POINTS, 0, vertexCount);
        gl.endTransformFeedback();
        gl.disable(gl.RASTERIZER_DISCARD);
        gl.bindBufferBase(gl.TRANSFORM_FEEDBACK_BUFFER, 0, null);
        gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, null);
        gl.bindBuffer(gl.TRANSFORM_FEEDBACK_BUFFER, null);

        //gl.bindBuffer(gl.COPY_READ_BUFFER, norBuf);
        //gl.bindBuffer(gl.COPY_WRITE_BUFFER, norBuf);
        //gl.bufferData(gl.COPY_WRITE_BUFFER, bufferSize, gl.DYNAMIC_COPY);
        //gl.copyBufferSubData(gl.COPY_READ_BUFFER, gl.COPY_WRITE_BUFFER, 0, 0, bufferSize);
        //gl.bindBuffer(gl.COPY_READ_BUFFER, null);
        //gl.bindBuffer(gl.COPY_WRITE_BUFFER, null);
      } else {
        gl.bindBuffer(gl.ARRAY_BUFFER, this.clientBuffers.norBuf);
        gl.bufferData(gl.ARRAY_BUFFER, sourceBuffers.compressedNormals, gl.DYNAMIC_DRAW);
      }
    }

    gl.useProgram(saveShader);
    gl.bindBuffer(gl.ARRAY_BUFFER, saveVb);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, saveIb);
    gl.bindVertexArray(saveVa);

    return true;
  }

  // Get the srgb texture format depending on our WebGL version.
  protected _getSrgbTextureFormat(): { internal: number; external: number } {
    const { gl, caps } = this;

    if (caps.webgl2 || gl instanceof WebGL2RenderingContext) {
      return {
        internal: (gl as WebGL2RenderingContext).SRGB8_ALPHA8,
        external: gl.RGBA,
      };
    }

    // Otherwise load the webgl1 extension.
    const extension = gl.getExtension('EXT_sRGB');

    // On some old browsers this extension doesn't exist,
    // so we'll just give up and return standard RGBA.
    if (!extension) {
      return { internal: gl.RGBA, external: gl.RGBA };
    }

    return {
      internal: extension.SRGB_EXT,
      external: extension.SRGB_EXT,
    };
  }

  protected _copyTexture(source: WebGLTexture, destination: WebGLTexture, width: number, height: number, useGammaCorrection: boolean): void {
    const { gl, texCopyShader } = this;
    const saveVb = gl.getParameter(gl.ARRAY_BUFFER_BINDING);
    const saveShader = gl.getParameter(gl.CURRENT_PROGRAM);
    const saveViewport = gl.getParameter(gl.VIEWPORT);
    const saveScissor = gl.isEnabled(gl.SCISSOR_TEST);
    const saveCulling = gl.isEnabled(gl.CULL_FACE);
    const saveBlend = gl.isEnabled(gl.BLEND);
    const saveActiveTexture = gl.getParameter(gl.ACTIVE_TEXTURE);
    const saveVertexAttribBufferBiding = gl.getVertexAttrib(texCopyShader.vertexAttribLoc, gl.VERTEX_ATTRIB_ARRAY_BUFFER_BINDING);
    const saveVertexAttribEnabled = gl.getVertexAttrib(texCopyShader.vertexAttribLoc, gl.VERTEX_ATTRIB_ARRAY_ENABLED);
    const saveVertexAttribArraySize = gl.getVertexAttrib(texCopyShader.vertexAttribLoc, gl.VERTEX_ATTRIB_ARRAY_SIZE);
    const saveVertexAttribArrayType = gl.getVertexAttrib(texCopyShader.vertexAttribLoc, gl.VERTEX_ATTRIB_ARRAY_TYPE);
    const saveVertexAttribNormalized = gl.getVertexAttrib(texCopyShader.vertexAttribLoc, gl.VERTEX_ATTRIB_ARRAY_NORMALIZED);
    const saveVertexAttribArrayStride = gl.getVertexAttrib(texCopyShader.vertexAttribLoc, gl.VERTEX_ATTRIB_ARRAY_STRIDE);
    const saveVertexAttribOffset = gl.getVertexAttribOffset(texCopyShader.vertexAttribLoc, gl.VERTEX_ATTRIB_ARRAY_POINTER);

    gl.activeTexture(gl.TEXTURE0);
    const saveTex = gl.getParameter(gl.TEXTURE_BINDING_2D);

    // Our destination texture may be set to use the SRGB format when using
    // gamma correction, however SRGB isn't a renderable format so here
    // we'll change the format to RGBA when performing gamma correction.
    if (useGammaCorrection && !this.createOptions.useImmutableTexture) {
      gl.bindTexture(gl.TEXTURE_2D, destination);
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    }

    // prev texture and fbo binding will get restored below at the end of this whole `if (videoSampleIndex > -1)` block
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.fbo2);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, destination, 0);

    gl.viewport(0, 0, width, height);
    gl.disable(gl.SCISSOR_TEST);
    gl.disable(gl.CULL_FACE);
    gl.disable(gl.BLEND);

    // don't really care what color we clear with (one less state to save and restore) but clearing to a known color like green could be useful for debugging.
    // gl.clearColor(0.0, 1.0, 0.0, 1.0);

    // clear to suppress buffer load on mobile
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.bindTexture(gl.TEXTURE_2D, source);

    gl.useProgram(texCopyShader);
    gl.uniform1i(texCopyShader.textureSamplerLoc, 0);
    gl.uniform1i(texCopyShader.useGammaCorrectionLoc, Number(useGammaCorrection));

    gl.bindBuffer(gl.ARRAY_BUFFER, this.texCopyVerts);
    gl.enableVertexAttribArray(texCopyShader.vertexAttribLoc);
    gl.vertexAttribPointer(texCopyShader.vertexAttribLoc, 2, gl.FLOAT, false, 0, 0);

    gl.drawArrays(gl.TRIANGLES, 0, 3);

    // restore all the states we touched:
    gl.useProgram(saveShader);

    // bind the buffer the previous vertex attribute binding applied to (which may or may not be the same as saveVb)
    gl.bindBuffer(gl.ARRAY_BUFFER, saveVertexAttribBufferBiding);
    gl.vertexAttribPointer(
      texCopyShader.vertexAttribLoc,
      saveVertexAttribArraySize,
      saveVertexAttribArrayType,
      saveVertexAttribNormalized,
      saveVertexAttribArrayStride,
      saveVertexAttribOffset
    );

    if (saveVertexAttribEnabled) {
      gl.enableVertexAttribArray(texCopyShader.vertexAttribLoc);
    } else {
      gl.disableVertexAttribArray(texCopyShader.vertexAttribLoc);
    }

    gl.bindBuffer(gl.ARRAY_BUFFER, saveVb);
    gl.viewport(saveViewport[0], saveViewport[1], saveViewport[2], saveViewport[3]);

    if (saveScissor) {
      gl.enable(gl.SCISSOR_TEST);
    }

    if (saveBlend) {
      gl.enable(gl.BLEND);
    }

    if (saveCulling) {
      gl.enable(gl.CULL_FACE);
    }

    gl.bindTexture(gl.TEXTURE_2D, saveTex);

    gl.activeTexture(saveActiveTexture);
  }

  public setBuffers(clientBuffers: WebGlClientBuffers) {
    this.clientBuffers = clientBuffers;
  }

  public setBuffersFromTypedArrays(clientBuffers: TypedArrayClientBuffers) {
    this.clientBuffers = clientBuffers;
  }

  // Write our decompressed mesh data back to our client.
  public updateClientBuffers(
    indices?: Uint16Array | Uint32Array,
    positions?: Float32Array,
    uvs?: Uint16Array,
    normals?: Uint8Array | Uint16Array | Float32Array,
    staticDraw?: boolean
  ): void {
    var saveVb = this.gl.getParameter(this.gl.ARRAY_BUFFER_BINDING);
    var saveIb = this.gl.getParameter(this.gl.ELEMENT_ARRAY_BUFFER_BINDING);

    const usage = staticDraw ? this.gl.STATIC_DRAW : this.gl.DYNAMIC_DRAW;

    if (indices) {
      if (this.clientBuffers.indexBuf instanceof WebGLBuffer) {
        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.clientBuffers.indexBuf);
        this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, indices, usage);
      } else {
        this.clientBuffers.indexBuf.set(indices);
      }
    }

    if (positions) {
      if (this.clientBuffers.posBuf instanceof WebGLBuffer) {
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.clientBuffers.posBuf);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, positions, usage);
      } else {
        this.clientBuffers.posBuf.set(positions);
      }
    }

    if (uvs) {
      if (this.clientBuffers.uvBuf instanceof WebGLBuffer) {
        // don't need to un-quantized values we'll tell glVertexAttribPointer to do it
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.clientBuffers.uvBuf);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, uvs, usage);
      } else {
        this.clientBuffers.uvBuf.set(uvs);
      }
    }

    if (normals) {
      if (this.clientBuffers.norBuf instanceof WebGLBuffer) {
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.clientBuffers.norBuf);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, normals, usage);
      } else {
        this.clientBuffers.norBuf.set(normals);
      }
    }

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, saveVb);
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, saveIb);
  }

  public setTexture(image: HTMLImageElement): void {
    const gl = this.gl as WebGL2RenderingContext;

    gl.pixelStorei(gl.PACK_ALIGNMENT, 4);
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false);

    const saveTex = gl.getParameter(gl.TEXTURE_BINDING_2D);
    gl.bindTexture(gl.TEXTURE_2D, this.clientBuffers.tex);

    const useGammaCorrection = !!this.createOptions.outputLinearTextures;
    const srgbFormat = this._getSrgbTextureFormat();
    const defaultFormat = { internal: gl.RGBA, external: gl.RGBA };

    const format = useGammaCorrection ? srgbFormat : defaultFormat;

    if (this.createOptions.useImmutableTexture) {
      const { width, height } = image;

      gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, image);
    } else {
      gl.texImage2D(gl.TEXTURE_2D, 0, format.internal, format.external, gl.UNSIGNED_BYTE, image);
    }

    gl.bindTexture(gl.TEXTURE_2D, saveTex);
  }

  public copyVideoTexture(): void {
    const { videoWidth, videoHeight } = this._fileInfo;
    const useGammaCorrection = !!this.createOptions.outputLinearTextures;

    const useAsyncDecode = this.caps.webgl2 && !this.createOptions.disableAsyncDecode;
    const textureSource = useAsyncDecode ? this.textures[this.readPbo] : this.textures[0];

    const gl = this.gl;

    const saveFbo = gl.getParameter(gl.FRAMEBUFFER_BINDING);
    const saveTex = gl.getParameter(gl.TEXTURE_BINDING_2D);

    // Use shader and full screen triangle like Android Unity plugin does.
    this._copyTexture(textureSource, this.clientBuffers.tex, videoWidth, videoHeight, useGammaCorrection);

    gl.bindFramebuffer(gl.FRAMEBUFFER, saveFbo);
    gl.bindTexture(gl.TEXTURE_2D, saveTex);
  }

  protected _getWatermarkAsync(currentVideo: HTMLVideoElement): Uint8Array | null {
    const gl = this.gl as WebGL2RenderingContext;
    let result: Uint8Array | null = null;

    const savePbo = gl.getParameter(gl.PIXEL_PACK_BUFFER_BINDING);
    // clear any current error:
    let error = gl.getError();
    if (error != gl.NO_ERROR && error != gl.CONTEXT_LOST_WEBGL) {
      this._logger._logWarning('WebGlBackend._getWatermarkAsync discarding prior webgl error: ' + error);
    }

    this.readPbo = (this.nextPbo + 1) % this.pixelBuffers.length;

    if (this.readFences[this.readPbo] != null) {
      var status = gl.getSyncParameter(this.readFences[this.readPbo], gl.SYNC_STATUS);

      // Disable this for now as it adds an unpredictable amout of latency to video decoding (beyond 'numAsyncFrames' number of frames)
      // instead we'll just read PBO when we need to even if this results in a stall (and webgl warnings in the console)
      //if (status == gl.UNSIGNALED) {
      //  this._logInfo("fence not signaled for readPbo = " + this.readPbo);
      //  return false;
      //}

      gl.deleteSync(this.readFences[this.readPbo]);
      this.readFences[this.readPbo] = null;

      gl.bindBuffer(gl.PIXEL_PACK_BUFFER, this.pixelBuffers[this.readPbo]);
      gl.getBufferSubData(gl.PIXEL_PACK_BUFFER, 0, this.watermarkPixels, 0, this.watermarkPixels.byteLength);

      result = this.watermarkPixels;

      //this._logInfo("read pbo " + readPbo + " -> frame index " + videoSampleIndex);

      // At this point we know that frame 'videoSampleIndex' is contained in textures[readPbo], but we don't want to copy it to client texture
      // until we know we have the matching mesh frame.
    }

    if (!this.pixelBuffers[this.nextPbo]) {
      this.pixelBuffers[this.nextPbo] = gl.createBuffer();
      gl.bindBuffer(gl.PIXEL_PACK_BUFFER, this.pixelBuffers[this.nextPbo]);
      gl.bufferData(gl.PIXEL_PACK_BUFFER, this.watermarkPixels.byteLength, gl.DYNAMIC_READ);
    }

    // fill 'nextPbo' texture slice with current contents of video and start an async readback of the watermark pixels
    //this._logInfo("video -> texture slice " + this.nextPbo);

    gl.pixelStorei(gl.PACK_ALIGNMENT, 4);
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false);

    gl.bindTexture(gl.TEXTURE_2D, this.textures[this.nextPbo]);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, currentVideo);

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.fbo1);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.textures[this.nextPbo], 0);
    gl.bindBuffer(gl.PIXEL_PACK_BUFFER, this.pixelBuffers[this.nextPbo]);
    gl.readPixels(0, 0, this.watermarkPixels.byteLength / 4, 1, gl.RGBA, gl.UNSIGNED_BYTE, 0);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    if (gl.getError() == gl.NO_ERROR) {
      //this._logInfo("read texture slice " + this.nextPbo + " -> pbo " + this.nextPbo);
      this.readFences[this.nextPbo] = gl.fenceSync(gl.SYNC_GPU_COMMANDS_COMPLETE, 0);
      this.nextPbo = (this.nextPbo + 1) % this.pixelBuffers.length;
    } else {
      this._logger._logWarning('webgl error: ' + error + ' skipping video texture read');
    }

    gl.bindBuffer(gl.PIXEL_PACK_BUFFER, savePbo);

    return result;
  }

  protected _getWatermarkSync(currentVideo: HTMLVideoElement): Uint8Array | null {
    const gl = this.gl;
    let result: Uint8Array | null = null;

    gl.pixelStorei(gl.PACK_ALIGNMENT, 4);
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false);

    gl.bindTexture(gl.TEXTURE_2D, this.textures[0]);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, currentVideo);

    const error = gl.getError();

    if (error == gl.NO_ERROR) {
      gl.bindFramebuffer(gl.FRAMEBUFFER, this.fbo1);
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.textures[0], 0);
      gl.readPixels(0, 0, this.watermarkPixels.byteLength / 4, 1, gl.RGBA, gl.UNSIGNED_BYTE, this.watermarkPixels);

      result = this.watermarkPixels;
    } else {
      this._logger._logWarning('webgl error: ' + error + ' skipping video texture read');
    }
    return result;
  }

  public getWatermark(currentVideo: HTMLVideoElement): [Uint8Array | null, boolean] {
    if (!this.watermarkPixels) {
      this.watermarkPixels = new Uint8Array(this._fileInfo.videoWidth * 4);
    }

    const gl = this.gl;

    const saveFbo = gl.getParameter(gl.FRAMEBUFFER_BINDING);
    const saveTex = gl.getParameter(gl.TEXTURE_BINDING_2D);

    const useAsyncDecode = this.caps.webgl2 && !this.createOptions.disableAsyncDecode;

    let result: Uint8Array | null = useAsyncDecode ? this._getWatermarkAsync(currentVideo) : this._getWatermarkSync(currentVideo);

    gl.bindFramebuffer(gl.FRAMEBUFFER, saveFbo);
    gl.bindTexture(gl.TEXTURE_2D, saveTex);

    return [result, useAsyncDecode];
  }
}
